import axios from 'axios';
import {makeAutoObservable, runInAction} from 'mobx';
import {logger} from 'src/lib';
import RootStore from '.';
import _ from 'lodash';
import {Account} from './domainObjects/Account';
import {AccountT, MinUserT} from 'src/types';

export type AccountResponseT = AccountT & {
  usage?: number;
  riskScore?: number;
  noUserMatch?: MinUserT[];
  partialUserMatch?: MinUserT[];
  perfectUserMatch?: MinUserT[];
};

class AccountsStore {
  accountsMap: Record<string, Account> = {};
  accessesCount: _.Dictionary<number> = {};

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this);
  }

  updateAccountFromServer({
    forUser,
    editedByUser,
    createdByUser,
    ...attributes
  }: Partial<AccountResponseT> & {id: string}) {
    // ignore placeholder accounts

    const existingAccount = this.accountsMap[attributes.id];
    if (existingAccount) {
      Object.assign(existingAccount, attributes);
    } else {
      this.accountsMap[attributes.id!] = new Account(this.rootStore, attributes);
    }

    _.compact([forUser, editedByUser, createdByUser]).map((user) =>
      this.rootStore.usersStore.updateUserFromServer(user)
    );
  }

  async unassignAccounts(accounts: Account[]) {
    if (_.isEmpty(accounts)) {
      return;
    }
    for (let index = 0; index < accounts.length; index++) {
      const element = accounts[index];
      try {
        await axios.post(`/api/accounts/${element.id}/unassign`);
        runInAction(() => (element.uid = undefined));
      } catch (err) {
        logger.error(err);
        this.rootStore.toast.add(
          `Error while unlinking ${element?.account}: ${_.get(
            err,
            'response.data.error.message',
            'Something went wrong'
          )}`,
          {
            appearance: 'error',
            autoDismiss: false,
          }
        );
      }
    }
  }

  setFlaggedForReview(ids: string[], value: boolean) {
    // ignore any accounts that already have the desired value
    const accountsToUpdate = ids.filter((id) => this.accountsMap[id].flaggedForReview !== value);
    runInAction(() => accountsToUpdate.forEach((id) => (this.accountsMap[id].flaggedForReview = value)));
    axios.post('/api/accounts/set_flagged_for_review', {ids, value}).catch((err) => {
      // assume changes were not made in the backend, so revert frontend state
      runInAction(() => accountsToUpdate.forEach((id) => (this.accountsMap[id].flaggedForReview = !value)));
      logger.error(err);
      this.rootStore.toast.add(`Failed to ${value ? 'flag' : 'unflag'} account.`, {
        appearance: 'error',
        autoDismiss: true,
      });
    });
  }

  async markAsReviewed(ids: string[]) {
    const {data: reviewedAt} = await axios.post('/api/accounts/set_as_reviewed', {ids});
    runInAction(() => ids.forEach((id) => (this.accountsMap[id].reviewedAt = reviewedAt)));
  }

  async createAccount(account: {rid: string; uid: string} & Partial<AccountT>) {
    try {
      const res = await axios.post('/api/accounts', {newAccount: account});
      runInAction(() => {
        this.updateAccountFromServer({...account, id: res.data.id});
        const system = this.rootStore.newResourceStore.resourceMap[account.rid].rootResource;
        system.accountIds = _.union(system.accountIds, [res.data.id]);
      });
      return this.accountsMap[res.data.id];
    } catch (err) {
      this.rootStore.toast.add(`Failed to create Account.`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  async loadAccessesCount(sid: string): Promise<_.Dictionary<number>> {
    try {
      const {data} = await axios.get<_.Dictionary<number>>(`/api/accounts/accesses_sum_up?sid=${sid}`);

      runInAction(() => {
        this.accessesCount = data;
      });
      return data;
    } catch (err) {
      this.rootStore.toast.error('Error loading acceses count');
    }
    return {};
  }

  get accounts() {
    return _.sortBy(_.values(this.accountsMap), 'account');
  }

  get hasUnlinkedAccounts() {
    return !_.isEmpty(this.accounts.filter((account) => _.isEmpty(account.forUser)));
  }

  get linkedAccounts() {
    return this.accounts.filter((account) => !_.isEmpty(account.forUser));
  }

  userLinkedAccounts(uid: string) {
    return this.accounts.filter((account) => !_.isEmpty(account.forUser) && account.forUser?.id === uid);
  }
}

export default AccountsStore;
