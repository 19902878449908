import _ from 'lodash';
import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {SubmitButton} from 'src/components/design';

import {Formik, FormikForm} from 'src/components/design/formik';
import {SensitivitySettingT} from 'src/types';
import {Icon, Loading} from '@trustle/component-library';
import {SensitivityDefaultSelector} from 'src/components/sensitivity';
import {useRootStore} from 'src/lib/hooks';
import {Button, Modal, TextAreaInput} from '@trustle/component-library';
import {Field} from 'formik';
import {useToasts} from 'react-toast-notifications';
import {observer} from 'mobx-react';
import SensitivityLevelParameters from './SensitivityLevelParameters';

type AdvancedModalPropsT = {
  sensitivitySetting: SensitivitySettingT;
  onClose: (modified?: boolean) => void;
};

function AdvancedSensitivitySettingsModal(props: AdvancedModalPropsT) {
  const {sensitivitySetting} = props;
  const {org} = useRootStore();

  async function saveChanges(values: any) {
    await org!.updateSensitivitySettings(sensitivitySetting.id, values);

    props.onClose(true);
  }

  const DescriptionLabel = ({message}: {message: string}) => {
    return (
      <div className="tr-flex tr-items-center">
        {message}
        <span className="tr-text-error tr-pl-1">
          <Icon type="warning" forcedSize={20} />
        </span>
      </div>
    );
  };

  return (
    <Modal
      visible={true}
      onClose={() => {
        props.onClose();
      }}
      title={`Edit Sensitivity "${sensitivitySetting.level}"`}
    >
      <Formik
        initialValues={{
          approvalDefinition: sensitivitySetting.approvalDefinition || '',
          deprovisionDefinition: sensitivitySetting.deprovisionDefinition || '',
          provisionDefinition: sensitivitySetting.provisionDefinition || '',
        }}
        onSubmit={saveChanges}
      >
        {({isSubmitting}) => {
          return (
            <FormikForm>
              <div className="tr-flex tr-flex-col">
                <div className="tr-flex">
                  <Field
                    component={TextAreaInput}
                    label="Approval Job Definition"
                    description={<DescriptionLabel message={`Warning - advanced. `} />}
                    name="approvalDefinition"
                    rows={4}
                    required
                  />
                </div>
                <div className="tr-flex">
                  <Field
                    component={TextAreaInput}
                    label="Provision Job Definition"
                    description={<DescriptionLabel message={`Warning - advanced. `} />}
                    name="provisionDefinition"
                    rows={4}
                    required
                  />
                </div>
                <div className="tr-flex">
                  <Field
                    component={TextAreaInput}
                    label="Deprovision Job Definition"
                    description={<DescriptionLabel message={`Warning - advanced. `} />}
                    name="deprovisionDefinition"
                    rows={4}
                    required
                  />
                </div>
              </div>
              <div className="tr-flex tr-justify-end">
                <Button
                  onClick={() => {
                    props.onClose();
                  }}
                >
                  {'Cancel'}
                </Button>
                <SubmitButton label="Save" name="saveUpdate" inSubmit={isSubmitting} />
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </Modal>
  );
}

const SensitivitySettings = observer(() => {
  const {org} = useRootStore();
  const {addToast} = useToasts();

  const [sensitivitySettings, setSensitivitySettings] = useState<SensitivitySettingT[]>(org.sensitivitySettings);
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCleanState, setCleanSlate] = useState(true);
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [sensitivityTab, setSensitivityTab] = useState(_.startCase(org.defaultSensitivitySetting?.level));
  const [showAdvancedSettings, setShowAdvancedSettings] = useState<SensitivitySettingT | null>(null);
  const [updatedStates, setUpdatedStates] = useState<{
    defaultSensitivityId: string | null;
    settings: _.Dictionary<SensitivitySettingT>;
  }>({defaultSensitivityId: null, settings: {}});

  async function loadConfiguration() {
    setIsLoading(true);
    const sensitivitiesResult = await axios.get('/api/sensitivities');
    const {sensitivitySettings} = sensitivitiesResult.data;
    setSensitivitySettings(sensitivitySettings);
    setIsLoading(false);
  }

  useEffect(() => {
    if (_.isNil(sensitivitySettings) && !isLoading) {
      void loadConfiguration();
    }
  });

  useEffect(() => {
    setSensitivityTab(_.startCase(org.defaultSensitivitySetting?.level));
  }, [org.defaultSensitivityId]);

  if (isLoading || _.isNil(org)) {
    return <Loading />;
  }

  function updateSensitivity(targetSetting: SensitivitySettingT, updatedValues: Partial<SensitivitySettingT>) {
    const newSettings = _.map(sensitivitySettings, (setting: SensitivitySettingT) => {
      if (setting.id !== targetSetting.id) {
        return _.clone(setting);
      }
      return _.defaults({}, updatedValues, setting);
    });
    setUpdatedStates(_.merge({}, updatedStates, {settings: {[targetSetting.id]: updatedValues}}));
    setSensitivitySettings(newSettings);
    setCleanSlate(false);
  }

  async function saveSensitivityChanges(): Promise<void> {
    setUpdateInProgress(true);

    if (updatedStates.defaultSensitivityId) {
      await org!.setDefaultSensitivityId(updatedStates.defaultSensitivityId);
    }
    for (const [settingId, updatedValues] of Object.entries(updatedStates.settings)) {
      await org!.updateSensitivitySettings(settingId, updatedValues);
    }
    addToast('Successfully updated settings', {appearance: 'success', autoDismiss: true});

    setUpdatedStates({defaultSensitivityId: null, settings: {}});
    setCleanSlate(true);
    setUpdateInProgress(false);
  }

  return (
    <div className="admin-sensitivity-scores my-3">
      <div>
        <div className="tr-flex tr-flex-col">
          <h3>Global Sensitivity Score Settings</h3>
          <div className="tr-mb-6">These settings define the sensitivity settings for each resource.</div>
          <div className="tr-text-md tr-font-bold tr-pb-2">Default Level</div>
          <p className="tr-text-sm tr-mb-6">
            The default level is applied to each existing and newly created resource. If you aren’t sure what to set, we
            recommend <b>Level 2 Medium</b>, as you can always change the default on this screen, as well as change it
            on a per-resource basis.
          </p>
          <hr></hr>

          <SensitivityDefaultSelector
            sensitivityId={org.defaultSensitivitySetting?.id}
            sensitivitySettings={sensitivitySettings!}
            onSelect={({sensitivityId}: {sensitivityId: string}) => {
              setUpdatedStates(_.merge({}, updatedStates, {defaultSensitivityId: sensitivityId}));
              setCleanSlate(false);
              const setting = _.find(sensitivitySettings, {id: sensitivityId});
              setSensitivityTab(_.startCase(setting?.level));
            }}
          />
        </div>
      </div>
      <div className="tr-py-3 tr-mb-12">
        <div className="tr-flex tr-flex-col">
          <div className="tr-flex tr-my-3">
            <div className="tr-flex tr-flex-col tr-w-full">
              <div className="tr-text-md tr-font-bold tr-pb-2">Level Parameters</div>
              <div className="tr-text-sm tr-pb-3">Set global constraints for each level of sensitivity.</div>

              <SensitivityLevelParameters
                sensitivitySettings={sensitivitySettings}
                updateSensitivity={updateSensitivity}
                setShowAdvancedSettings={setShowAdvancedSettings}
                sensitivityTab={sensitivityTab}
                setSensitivityTab={setSensitivityTab}
                setError={setError}
              />
            </div>
          </div>
          <div className="tr-flex">
            <div className="tr-flex tr-flex-col tr-text-left">
              <Button
                onClick={() => {
                  void saveSensitivityChanges();
                }}
                disabled={updateInProgress || isCleanState || error}
                data-testid="save-sensitivity-levels"
              >
                {'Save'}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {showAdvancedSettings && (
        <AdvancedSensitivitySettingsModal
          sensitivitySetting={showAdvancedSettings}
          onClose={(isModified?: boolean) => {
            setShowAdvancedSettings(null);
            if (isModified) {
              void loadConfiguration();
            }
          }}
        />
      )}
    </div>
  );
});

export default SensitivitySettings;
