import {ALL_LICENSES_KEY} from '../../constants';
import {Icon} from '@trustle/component-library';
import React from 'react';

export function resolveFillColor(serie: string) {
  switch (serie) {
    case 'copilot':
      return '#5183A1';
    case 'powerbi':
      return '#0D2357';
    case 'visio':
      return '#8D2C72';
    case 'e1':
      return '#26A666';
    case 'e3':
      return '#FE7637';
    case 'e5':
      return '#FFA600';
    case ALL_LICENSES_KEY:
      return USAGE_COLOR;
    default:
      return 'none';
  }
}

export const EXPOSURE_COLOR = '#D3D3D3';
export const USAGE_COLOR = '#0091FF';

export type LicenseUsageChartProps = {
  data: any[];
  series: string[];
  summarizeAllLicenses?: boolean;
  noDataMessage?: string;
};

export function formatDateForLegend(key: string) {
  const format = (key: string) => {
    const [year, month, day] = key.split('-');
    return `${month}/${day}/${year.slice(2)}`;
  };

  const splitted = key.split('|');
  if (splitted.length === 1) {
    return format(key);
  } else {
    return `${format(splitted[0])} - ${format(splitted[1])}`;
  }
}

export function renderXAxisReferenceLabel({viewBox: {x, y}}: any) {
  const d = 28;
  const r = d / 2;

  const transform = `translate(${x - r} ${y - d - 4})`;

  return (
    <g transform={transform}>
      <Icon type="Trustle" color="red" size="md" />
    </g>
  );
}
