import _ from 'lodash';
import React, {useEffect} from 'react';
import {useState} from 'react';
import {formatDateFromNow, useFeatureFlags} from 'src/lib';
import {Table} from '@trustle/component-library';
import {observer} from 'mobx-react-lite';
import axios from 'axios';
import {AccessRecordElemT, AccountT, PermissionT, ResourceT, TeamT, TicketT, UserT} from 'src/types';
import {OrgEventEnum} from './types';
import EventTemplate from './EventTemplate';
import EventHeader from './EventHeader';
import ReportDropdown from '../admin/reports/ReportDropdown';
import moment from 'moment';
import {DateRangePicker, FocusedInputShape} from 'react-dates';

export type OrgEventsDataParamsT = {
  access?: Partial<AccessRecordElemT> & {oldValue?: Partial<AccessRecordElemT>};
  actor?: Partial<UserT> & {oldValue?: Partial<UserT>};
  accessChange?: any;
  connection?: any & {error?: any};
  expiredJob?: {
    id?: string;
  };
  import?: {
    id?: string;
    message?: string;
    status?: string;
  };
  job?: {
    id?: string;
  };
  authority?: Partial<any> & {oldValue?: Partial<any>};
  permission?: Partial<PermissionT> & {oldValue?: Partial<PermissionT>};
  request?: {
    id?: string;
  };
  ticket?: Partial<TicketT> & {oldValue?: Partial<TicketT>};
  system?: Partial<ResourceT> & {oldValue?: Partial<ResourceT>};
  resource?: Partial<ResourceT> & {oldValue?: Partial<ResourceT>; provision?: string};
  team?: Partial<TeamT> & {oldValue?: Partial<TeamT>};
  user?: Partial<UserT> & {method?: string; oldValue?: Partial<UserT>};
  userAccount?: Partial<AccountT> & {oldValue?: Partial<AccountT>};
  description?: {
    trustle?: string;
    customer?: string;
  };
};
export type OrgEventsT = {
  data: OrgEventsDataParamsT;
  description?: {
    trustle?: string;
    customer?: string;
  };
  type: OrgEventEnum;
};
export type FiltersT = {page: number; sizePerPage: number; data: OrgEventsT[]; total: number};

type PropsT = {entityType: string; id: string};

export const camelize = (obj: any) =>
  _.transform(obj, (acc: any, value, key: string, target) => {
    const camelKey = _.isArray(target) ? key : _.camelCase(key);
    acc[camelKey] = _.isDate(value) ? value.toISOString() : _.isObject(value) ? camelize(value) : value;
  });

const OrgEvents = observer(({entityType, id}: PropsT) => {
  const featureFlags = useFeatureFlags();
  const orgEventsReportsHidden = featureFlags.isEnabled('hide_reports_until_peryton_events_stable');
  const [startDate, setStartDate] = useState<moment.Moment | null>(moment().startOf('year'));
  const [endDate, setEndDate] = useState<moment.Moment | null>(moment());
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);
  const [filters, setFilters] = useState<FiltersT>({
    page: 1,
    total: 0,
    data: [],
    sizePerPage: 50,
  });
  const getEntityUrlParam = () => {
    const query: any = {};
    switch (entityType) {
      case 'access':
        query.access_id = id;
        break;
      case 'resources':
        query.resource_id = id;
        break;
      case 'teams':
        query.team_id = id;
        break;
      case 'accounts':
        query.account_id = id;
        break;
      case 'permissions':
        query.permission_id = id;
        break;
      case 'users':
        query.trustle_user_id = id;
        break;
    }
    return query;
  };

  const queryParams = new URLSearchParams({...getEntityUrlParam(), size: 10000});

  if (startDate) {
    queryParams.set('startDate', moment(startDate).format('YYYY-MM-DD'));
  }

  if (endDate) {
    queryParams.set('endDate', moment(endDate).format('YYYY-MM-DD'));
  }

  const handleDatesChange = ({startDate, endDate}: any) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleTableChange = async (_type: string, {page, sizePerPage}: {page: number; sizePerPage: number}) => {
    const events = await searchEvents(page, sizePerPage);
    setFilters({
      page,
      total: events.total,
      data: events.items,
      sizePerPage,
    });
  };

  const searchEvents = async (page: number, size: number) => {
    const query = getEntityUrlParam();
    const {data} = await axios.get<{items: any[]; total: number}>(`/api/orgs/events`, {
      params: {
        size,
        page,
        endDate: moment(endDate).format('YYYY-MM-DD'),
        startDate: moment(startDate).format('YYYY-MM-DD'),
        ...query,
      },
    });
    return camelize(data);
  };

  useEffect(() => {
    void handleTableChange('load', {page: 1, sizePerPage: 50});
  }, []);

  return (
    <div className="tr-mb-[100px]">
      {!orgEventsReportsHidden && (
        <div className="tr-flex tr-align-middle tr-justify-between">
          <div className="report-section ">
            <div className="tr-text-sm tr-font-bold">Set Date Range</div>
            <DateRangePicker
              startDate={startDate}
              startDateId="tata-start-date"
              endDate={endDate}
              appendToBody={true}
              minimumNights={0}
              showClearDates
              showDefaultInputIcon={false}
              hideKeyboardShortcutsPanel
              enableOutsideDays
              inputIconPosition="after"
              isOutsideRange={() => false}
              endDateId="data-end-date"
              onDatesChange={handleDatesChange}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            />
          </div>
          <ReportDropdown title="Generate Report" url={`/api/orgs/events_report/`} queryParams={queryParams} />
        </div>
      )}
      <Table
        data={filters.data}
        defaultSorted={[{dataField: 'created', order: 'desc'}]}
        bordered={true}
        noDataIndication={<>No events found</>}
        onTableChange={handleTableChange}
        remote={{
          filter: true,
          pagination: true,
          sort: false,
          cellEdit: false,
        }}
        onSizePerPageChange={(size) => {
          const tempfilters = {...filters};
          setFilters({...tempfilters, sizePerPage: size});
          void handleTableChange('updatedPage', {page: filters.page, sizePerPage: size});
          return {}; //Typing error in table
        }}
        sizePerPage={filters.sizePerPage}
        page={filters.page}
        totalSize={filters.total ?? 0}
        tableKey={`events${id}`}
        columns={[
          {dataField: 'id', hidden: true, text: 'id'},
          {
            dataField: 'name',
            text: '',
            sort: false,
            formatter: (_cell, row: OrgEventsT) => {
              return <EventHeader data={row.data} type={row.type} />;
            },
          },
          {
            dataField: 'description',
            text: '',
            style: {textAlign: 'left'},
            formatter: (_cell, row: OrgEventsT) => {
              return (
                <div className="text-left tr-ml-2 tr-mb- tr-flex-col">
                  <EventTemplate text={row.description?.customer ?? ''} data={row.data} />
                </div>
              );
            },
          },
          {
            dataField: 'createdAt',
            text: 'Created',
            style: {width: '12rem'},
            headerStyle: {width: '12rem'},
            sort: true,
            formatter: (created: string) => <>{formatDateFromNow(created)}</>,
          },
        ]}
      />
    </div>
  );
});

export default OrgEvents;
