import {Icon, SensitivityIcon, Tooltip} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react';
import React, {useRef, useState} from 'react';
import {OverlayTrigger} from 'react-bootstrap';

import {ObservableRiskColumn} from 'src/components/design/tables/SharedColumnDefinitions/ResourceTableColumns';
import {Resource} from 'src/stores/domainObjects/Resource';
import {AwsResourceType, ConnectionServiceE} from 'src/types';
import ResourceDropdown from '../Setup/ResourceDropdown';
import ConnectorStatus from 'src/components/StatusIndicator';
import ShowUserAccount from 'src/connectors/common/editConnection/ShowUserAccount';
import ImportStatusObject from '../ImportStatusObject';
import {useFeatureFlags} from 'src/lib/hooks';
import {Modal} from '@trustle/component-library';

import {Link} from 'react-router-dom';
import {Badge} from '@trustle/component-library';
import {ItemIconTypesEnum} from '@trustle/component-library/dist/types';
import LockedOrProvision from './LockedOrProvision';

type ResourceTabPanelT = {
  system: Resource;
};

type ModalSystemStateT = {
  type: 'disabled' | 'archived' | 'editDisabled';
  title: string;
  linkPath: string;
  linkText: string;
  visible: boolean;
  onClose: () => void;
};

export const SystemModal = observer((props: ModalSystemStateT) => {
  const {type, title, linkPath, linkText, visible, onClose} = props;

  return (
    <Modal
      visible={visible}
      title={
        <div className="tr-flex">
          <Icon type={type as ItemIconTypesEnum} title={title} size="md" />
          <span className="tr-pl-1">{title}</span>
        </div>
      }
      onClose={() => onClose()}
    >
      <div className="tr-flex">
        <div className="tr-flex-auto body5-normal tr-pl-1">
          {type !== 'editDisabled' ? (
            <>
              This system has been {type}. Please If you would like to use this system you must {linkText} it in &nbsp;
              <Link to={linkPath} onClick={() => onClose()}>
                Settings &gt;
              </Link>
            </>
          ) : (
            <>You are not an owner of this system, you can open it in read only mode.</>
          )}
        </div>
      </div>
    </Modal>
  );
});

const TreeTabHeader = observer((props: ResourceTabPanelT) => {
  const {system} = props;
  const containerRef = useRef(null);
  const featureFlagViewer = useFeatureFlags();

  const [showSystemDisabled, setShowSystemDisabled] = useState<boolean>(false);
  const [showSystemArchived, setShowSystemArchived] = useState<boolean>(false);
  const [showSystemReadOnly, setShowSystemReadOnly] = useState<boolean>(false);

  const importStatusObj = system.connector ? ImportStatusObject({importState: system.connector.lastImport}) : null;
  const canEdit = system.userIsOwner;

  if (!system) {
    return <></>;
  }
  return (
    <div className="tr-flex tr-flex-row tr-w-full tr-justfy-between tr-py-4 tr-px-2">
      <div className="tr-flex tr-flex-row align-items-center justify-content-start heading-section tr-pr-4">
        {importStatusObj && (
          <div className="ml-2 mr-2" ref={containerRef}>
            <div className="icon-with-popup">
              <OverlayTrigger
                placement={'bottom-start'}
                delay={{show: 250, hide: 400}}
                container={containerRef}
                popperConfig={{
                  modifiers: [{options: {boundariesElement: 'offsetParent'}}],
                }}
                overlay={(overlayProps) => {
                  const tooltipProps = _.omit(overlayProps, ['show', 'placement', 'arrowProps', 'popper']);
                  return (
                    <div className="icon-tooltip tr-bg-white tr-text-black p-1" {...tooltipProps}>
                      <ConnectorStatus status={importStatusObj.status}></ConnectorStatus>
                      <span className="body6 tooltip-text tr-mx-2">{importStatusObj.text}</span>
                      <ShowUserAccount
                        authzOwner={system.connector?.authzOwner}
                        label="Authorized by: "
                        className="mt-2 body6 black"
                      />
                    </div>
                  );
                }}
              >
                <div className={`${importStatusObj.status === 'error' ? 'live' : ''} tr-p-1`}>
                  <span className="tr-flex tr-justify-center tr-items-center">{importStatusObj.icon}</span>
                </div>
              </OverlayTrigger>
            </div>
          </div>
        )}

        <div className="tr-ml-3 tr-mr-1">
          <div className="tr-flex tr-items-center">
            <div>
              <strong className="tr-truncate">{system.name}</strong>
            </div>
            <Tooltip className="tr-text-black tr-min-w-[150px]" content={system.description}>
              <Icon type="moreInfo" size="sm" className="tr-inline-block tr-text-white" />
            </Tooltip>
          </div>

          <div className="tr-flex align-items-center body6 ">
            <Icon type="resource" size="sm" />
            <span className="px-1">{_.size(system.childResources)}</span>
            <Icon type="permission" size="sm" />
            <span className="px-1">{_.size(system.permissions)}</span>
          </div>
        </div>
      </div>
      <div className="tr-flex tr-flex-row align-items-center justify-content-between tr-w-full tr-border-0 tr-border-l-[1px] tr-border-solid tr-border-white tr-pl-4">
        <SensitivityIcon size="md" level={system.sensitivity?.level ?? ''} colored={false} />

        <Badge
          variant="light"
          size="md"
          onClick={() => {}}
          className="tr-pointer tr-flex tr-items-center tr-mx-2 !tr-pl-1"
        >
          <LockedOrProvision target={system} addLabel ignoreLocked />
        </Badge>

        {system.archived && (
          <>
            <Badge
              variant="warning"
              size="md"
              onClick={() => setShowSystemArchived(true)}
              className="tr-pointer tr-bg-warning tr-mr-2 tr-flex tr-items-center tr-px-2"
            >
              <Icon type="archived" title="This resource is archived" size="sm" />
              <span className="tr-ml-1">{`Archived`}</span>
            </Badge>
            <SystemModal
              type="archived"
              title={'Archived System'}
              linkPath={`/resource/manage/${system.id}/settings`}
              linkText={'unarchive it'}
              visible={showSystemArchived}
              onClose={() => setShowSystemArchived(false)}
            />
          </>
        )}

        {system.isConnectedSystem &&
          system.connector?.disabled &&
          featureFlagViewer.isEnabled('toggle_connection_feature') && (
            <>
              <Badge
                variant="danger"
                size="md"
                onClick={() => setShowSystemDisabled(true)}
                className="tr-pointer tr-mr-2 tr-flex tr-items-center"
              >
                <Icon type="disabled" title="Resource currently disabled" />
                <span className="tr-ml-1">{`Disabled`}</span>
              </Badge>
              <SystemModal
                type="disabled"
                title={'Disabled System'}
                linkPath={`/resource/manage/${system.id}/settings/edit_connection`}
                linkText={'re-enable it'}
                visible={showSystemDisabled}
                onClose={() => setShowSystemDisabled(false)}
              />
            </>
          )}
        {!canEdit && (
          <div className="tr-ml-auto tr-mr-2">
            <Badge
              variant="secondary"
              size="md"
              onClick={() => setShowSystemReadOnly(true)}
              className="tr-pointer tr-flex tr-items-center"
            >
              <Icon
                type="locked"
                size="sm"
                title="You are not an owner of this system, you can open it in read only mode"
              />
              <span className="tr-ml-1">{`Read Only`}</span>
            </Badge>
            <SystemModal
              type="editDisabled"
              title={'Read Only System'}
              linkPath={''}
              linkText={'read only'}
              visible={showSystemReadOnly}
              onClose={() => setShowSystemReadOnly(false)}
            />
          </div>
        )}

        {system.type !== ConnectionServiceE.AWS ||
          (system.type === ConnectionServiceE.AWS && system.foreignId === AwsResourceType.ROLES && (
            <div className="ml-2 mr-2">
              <ObservableRiskColumn item={system} />
            </div>
          ))}
        {canEdit && <div className="grow" />}
        {canEdit && (
          <div className="actions tr-text-white">
            <ResourceDropdown resource={system} refreshResourceList={() => system.loadDetailed()} />
          </div>
        )}
      </div>
    </div>
  );
});

export default TreeTabHeader;
