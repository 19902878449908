import React from 'react';
import {Switch, Route} from 'react-router-dom';
import ConfigureAws from './aws/ConfigureAWS';
import STSTokenAWS from './aws/STSTokenAWS';
import ConfigureGApps from './gapps/ConfigureGAPPS';
import ConfigureGithub from './github/ConfigureGithub';
import ConfigureSAML from './authentication/ConfigureSAML';
import ConfigureOAuth from './authentication/ConfigureOAuth';
import ConfigureAzureAD from './azuread/ConfigureAzureAD';
import GenericContentGuide from './generic/GenericContentGuide';
import UsersUploadGuide from './users/UsersUploadGuide';
import ConfigureOkta from './okta/ConfigureOkta';
import AccountsCSVMappingGuide from './accounts/AccountsCSVMappingGuide';
import {ConfigureTableau} from './ConfigureTableau';
import ConfigureGCP from './gcp/ConfigureGCP';
import ConfigureM365 from './m365/ConfigureM365';
import ConfigureAWSIdentityCenter from './aws_identity_center/ConfigureAWSIdentityCenter';

function DocsBase() {
  return (
    <>
      <Switch>
        <Route path="/docs/admin/users/csv_guide" component={UsersUploadGuide} />
        <Route path="/docs/accounts/mapping/csv_guide" component={AccountsCSVMappingGuide} />
        <Route path="/docs/connectors/universal/csv_guide" component={GenericContentGuide} />
        <Route path="/docs/connectors/okta/okta_guide" component={ConfigureOkta} />
        <Route path="/docs/connectors/azure_ad/configure_azure_ad" component={ConfigureAzureAD} />
        <Route path="/docs/connectors/aws/configure_aws" component={ConfigureAws} />
        <Route path="/docs/connectors/gapps/configure_gapps" component={ConfigureGApps} />
        <Route path="/docs/connectors/gcp/configure_gcp" component={ConfigureGCP} />
        <Route path="/docs/connectors/aws/sts_token_aws" component={STSTokenAWS} />
        <Route path="/docs/connectors/github/configure_github" component={ConfigureGithub} />
        <Route path="/docs/connectors/tableau_setup" component={ConfigureTableau} />
        <Route path="/docs/connectors/m365/configure_m365" component={ConfigureM365} />
        <Route path="/docs/authentication/configure_saml" component={ConfigureSAML} />
        <Route path="/docs/authentication/configure_oauth" component={ConfigureOAuth} />
        <Route
          path="/docs/connectors/aws_identity_center/configure_aws_identity_center"
          component={ConfigureAWSIdentityCenter}
        />
      </Switch>
    </>
  );
}

export default DocsBase;
