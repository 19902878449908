import _ from 'lodash';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Alert, Button, Icon, Modal, Select} from '@trustle/component-library';
import {useRootStore} from 'src/lib/hooks';
import {observer} from 'mobx-react';
import {Search} from 'src/components/Search/Search';
import {UserType} from 'src/types';
import {Field, Form, Formik} from 'formik';
import {Team} from 'src/stores/domainObjects/Team';
import {UserFilter} from 'src/stores/domainObjects/Org';

import SaveFilterModal from './../SaveFilterModal';
import DeleteFilterModal from './../DeleteFilterModal';
import {User} from 'src/stores/domainObjects/User';
import {AddUsersDropdown} from '../../add-users-dropdown/AddUsersDropdown';

type PropsT = {
  filters: any;
  filteredUsers: User[];
  setFilters: any;
  setQuery: any;
};

const AdminUsersStatuses = observer(function AdminUsersCmp(props: PropsT) {
  const {filters, setFilters, setQuery, filteredUsers} = props;
  const {usersStore, currentUser, org, newResourceStore: resourceStore} = useRootStore();

  const history = useHistory();

  if (_.isNil(currentUser)) {
    return <></>;
  }

  const {loading} = usersStore;

  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(
    !currentUser.dismissedAlerts?.keys?.includes('inviteUsersToTrustle')
  );
  const departments = _.compact(_.uniq(_.map(filteredUsers, 'department')));
  const titles = _.compact(_.uniq(_.map(filteredUsers, 'title')));
  const roles = _.compact(_.uniq(_.map(filteredUsers, 'remoteRole')));
  const teams: Team[] = _.compact(_.uniq(_.flatMap(filteredUsers, 'memberTeams')));

  const userTypesOptions: string[] = _.values(UserType);
  const systemsOptions: string[] = _.uniq(_.map(resourceStore?.systems, 'name'));

  const [showModalFilter, setShowModalFilter] = useState<{show: boolean; isEditing: boolean}>({
    show: false,
    isEditing: false,
  });
  const handleFilterClose = (): void => setShowModalFilter({show: false, isEditing: false});

  const handleAfterSubmit = (): void => {
    setLoadedFilter(null);
    setFilters({});
  };

  const [loadedFilter, setLoadedFilter] = useState<UserFilter | null>(null);
  const [showModalDeleteFilter, setShowModalDeleteFilter] = useState<boolean>(false);

  const convertToOptions = (filter: string[]): {value: string; label: string}[] => {
    return filter.map((item: string) => ({id: item, value: item, label: _.upperFirst(item)}));
  };

  function handleFilterUpdate(selectedFilters: string[], filterName: string) {
    const valuesArray = _.map(selectedFilters, 'value');
    setFilters({...filters, [filterName]: valuesArray.join(',')});
  }

  function handleClearFilter(filterName: string) {
    setFilters({...filters, [filterName]: ''});
  }

  const LabelFilter = ({filterName}: any) => {
    return (
      <div className="tr-flex tr-justify-between">
        <div>{filterName}</div>
        {filters[filterName] && (
          <div
            className="tr-font-normal tr-text-trustle-link"
            role="button"
            onClick={() => handleClearFilter(filterName)}
          >
            Clear
          </div>
        )}
      </div>
    );
  };

  function filterToInitialValue(filterName: string) {
    return filters[filterName] ? _.flatMap(filters[filterName].split(', '), (str) => _.split(str, ',')) : [];
  }
  const initialTeam = filterToInitialValue('Team');
  const initialRole = filterToInitialValue('Role');
  const initialDepartment = filterToInitialValue('Department');
  const initialTitle = filterToInitialValue('Title');
  const initialSystem = filterToInitialValue('System');
  const initialUserTypes = filterToInitialValue('Trustle User Type');

  const initialValues = {
    team: initialTeam,
    department: initialDepartment,
    role: initialRole,
    title: initialTitle,
    userTypes: initialUserTypes,
    system: initialSystem,
  };

  const handleShowAdvancedSearch = () => {
    setShowAdvancedSearch(!showAdvancedSearch);
  };

  const FilterLoader = observer(() => {
    const loadedFilters = org.myFilters;

    const initialValues = {
      loadFilter: loadedFilter?.name,
    };

    const handleFilterLoad = (e: any) => {
      const optSelected = _.find(loadedFilters, (filter: UserFilter) => filter.name === e);
      const search = optSelected?.query;
      if (search) {
        history.push({...history.location, search});
        setLoadedFilter(optSelected);
      }
    };
    return (
      <>
        <div className="tr-flex tr-flex-col">
          <Formik initialValues={initialValues} onSubmit={() => {}}>
            <Form data-testid="filterBy-form">
              <Field
                component={Select}
                name={'loadFilter'}
                label={
                  <div className="tr-font-bold tr-text-trustle-navy tr-pr-2 tr-text-lg" data-testid="select-filter">
                    Filter By:
                  </div>
                }
                placeholder={'Saved Filters'}
                className="tr-mr-3 tr-w-[250px]"
                options={_.map(loadedFilters, (filter: UserFilter) => {
                  const {name, private: privated} = filter;
                  const labelCmp = (
                    <div className="tr-flex tr-items-center" data-testid={`filter-${name}`}>
                      {privated && (
                        <div className="tr-flex tr-mr-2 ">
                          <Icon type="visible" size="sm" title="private" />
                        </div>
                      )}

                      <span>{name}</span>
                    </div>
                  );
                  return {value: name, label: labelCmp};
                })}
                onChange={(e: any) => {
                  handleFilterLoad(e.value);
                }}
              />
            </Form>
          </Formik>
        </div>
      </>
    );
  });

  return (
    <>
      <div className={`${showAdvancedSearch && 'tr-rounded-2xl shadow-md tr-pb-2'} tr-flex-grow`}>
        <div className={`tr-flex tr-gap-4 tr-items-center${showAdvancedSearch ? ' tr-p-4' : ''} `}>
          <div className="tr-flex tr-items-center tr-flex-grow tr-max-w-[750px]">
            <Search
              className="flex-grow tr-relative"
              placeholder={`${
                showAdvancedSearch ? `Search using any filter` : `Search for Name, Email, Manager or Role`
              }`}
              filterOptions={{
                'Trustle User Type': _.values(UserType),
                Team: _.map(teams, (team: Team) => team.name),
                Role: _.values(roles),
                Department: _.values(departments),
                Title: _.values(titles),
                LinkStatus: ['Linked', 'Unlinked'],
                Invited: ['Pending', 'Sent', 'Expired'],
              }}
              onChange={(currentAccessQuery, filters) => {
                setQuery(currentAccessQuery);
                setFilters(filters);
              }}
              {...(!loading
                ? {
                    showCustomIcon: true,
                    handleCustomIcon: handleShowAdvancedSearch,
                    testidCustomIcon: 'btn-showAdvancedSearch',
                  }
                : {})}
            />
          </div>
          <div className="tr-max-w-max tr-flex">
            {showAdvancedSearch ? (
              <div className="tr-flex tr-items-center tr-justify-between tr-text-trustle-link">
                <Button
                  variant="ternary"
                  className="tr-mr-8 tr-flex tr-items-center"
                  onClick={() => {
                    setFilters({});
                    setLoadedFilter(null);
                  }}
                >
                  <Icon type="cancel" title="Clear filters" />
                  Clear all
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setShowModalFilter({show: true, isEditing: false})}
                  disabled={_.isEmpty(filters)}
                >
                  <Icon type="save" title="Save filter" />
                  <span>Save Filter View</span>
                </Button>
              </div>
            ) : (
              <>
                <AddUsersDropdown />
              </>
            )}
          </div>
        </div>
        {showModalFilter.show && (
          <Modal
            width={'md'}
            onClose={handleFilterClose}
            title={
              <div className="tr-flex tr-items-center">
                <Icon type="save" size="lg" title="Save filter" />
                <span>{showModalFilter.isEditing ? 'Edit ' : 'Save '} Filter View</span>
              </div>
            }
          >
            <SaveFilterModal
              onClose={handleFilterClose}
              afterSubmit={handleAfterSubmit}
              filters={filters}
              isEditing={showModalFilter.isEditing}
              loadedFilter={loadedFilter}
              loadedFilters={org.myFilters}
            />
          </Modal>
        )}
        {showModalDeleteFilter && (
          <Modal
            width={'md'}
            onClose={() => setShowModalDeleteFilter(false)}
            title={
              <div className="tr-flex tr-items-center">
                <Icon type="remove" size="lg" title="Remove filter" />
                <span>Delete Saved Filter?</span>
              </div>
            }
          >
            <DeleteFilterModal
              loadedFilter={loadedFilter}
              onClose={() => {
                setShowModalDeleteFilter(false);
                setLoadedFilter(null);
                setFilters({});
              }}
            />
          </Modal>
        )}
        {showAdvancedSearch && (
          <div className="tr-flex tr-flex-col tr-p-4" data-testid="buttons-edit-filter">
            <div className="tr-flex tr-items-end tr-pb-4">
              <FilterLoader />
              {loadedFilter && (
                <div className="tr-flex tr-pb-2">
                  <div role="button">
                    <Icon
                      type="edit"
                      title="Edit filter"
                      onClick={() => setShowModalFilter({show: true, isEditing: true})}
                      className="tr-px-4 tr-text-trustle-link"
                      data-testid="btn-editFilter"
                    />
                  </div>
                  <div role="button">
                    <Icon
                      type="remove"
                      title="private"
                      onClick={() => setShowModalDeleteFilter(true)}
                      className="tr-text-trustle-link"
                      data-testid="btn-removeFilter"
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="tr-flex-col">
              <Formik initialValues={initialValues} onSubmit={() => {}}>
                {({handleSubmit}) => {
                  return (
                    <Form onSubmit={handleSubmit}>
                      <div className="tr-flex">
                        <Field
                          component={Select}
                          name={'team'}
                          placeholder={'All'}
                          isMulti={true}
                          className="tr-mr-3 tr-w-[170px]"
                          label={<LabelFilter filterName="Team" />}
                          data-testid="select-Team"
                          options={_.map(teams, (team: Team) => {
                            const {id, name} = team;
                            return {id, value: name, label: name};
                          })}
                          onChange={(e: any) => {
                            handleFilterUpdate(e, 'Team');
                          }}
                          isDisabled={_.isEmpty(teams)}
                        />
                        <Field
                          component={Select}
                          name={'department'}
                          placeholder={'All'}
                          isMulti={true}
                          className="tr-mr-3 tr-w-[170px]"
                          label={<LabelFilter filterName="Department" />}
                          options={convertToOptions(departments)}
                          onChange={(e: any) => {
                            handleFilterUpdate(e, 'Department');
                          }}
                          isDisabled={_.isEmpty(departments)}
                        />

                        <Field
                          component={Select}
                          name={'role'}
                          placeholder={'All'}
                          isMulti={true}
                          className="tr-mr-3 tr-w-[170px]"
                          label={<LabelFilter filterName="Role" />}
                          options={convertToOptions(roles)}
                          onChange={(e: any) => {
                            handleFilterUpdate(e, 'Role');
                          }}
                          isDisabled={_.isEmpty(roles)}
                        />
                        <Field
                          component={Select}
                          name={'title'}
                          placeholder={'All'}
                          isMulti={true}
                          className="tr-mr-3 tr-w-[170px]"
                          label={<LabelFilter filterName="Title" />}
                          options={convertToOptions(titles)}
                          onChange={(e: any) => {
                            handleFilterUpdate(e, 'Title');
                          }}
                          isDisabled={_.isEmpty(titles)}
                        />
                        <Field
                          component={Select}
                          name={'userTypes'}
                          placeholder={'All'}
                          isMulti={true}
                          className="tr-mr-3 tr-w-[170px]"
                          label={<LabelFilter filterName="Trustle User Type" />}
                          options={convertToOptions(userTypesOptions)}
                          onChange={(e: any) => {
                            handleFilterUpdate(e, 'Trustle User Type');
                          }}
                          isDisabled={_.isEmpty(userTypesOptions)}
                        />
                        <Field
                          component={Select}
                          name={'system'}
                          placeholder={'All'}
                          isMulti={true}
                          className="tr-mr-3 tr-w-[170px]"
                          label={<LabelFilter filterName="System" />}
                          options={convertToOptions(systemsOptions)}
                          onChange={(e: any) => {
                            handleFilterUpdate(e, 'System');
                          }}
                          isDisabled={_.isEmpty(systemsOptions)}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        )}
      </div>
      {showAlert && (
        <div className="tr-flex tr-justify-end">
          <div className="tr-absolute tr-z-10 tr-ml-10 tr-mt-2">
            <Alert
              isFloater={true}
              title="Invite Users to Trustle"
              isBlinking={false}
              hideCloseButton={false}
              onClose={() => {
                void currentUser.disableAlert('inviteUsersToTrustle');
                setShowAlert(false);
              }}
            >
              <div className="flex">
                <div className="flex-1 tr-mr-8">
                  Now that you have created your users, you can invite them to join your Trustle Organization. “Sending
                  an invite” will send them a magic link to join your organization and activate their account.
                </div>
                <div className="flex-none" style={{marginTop: '-25px'}}></div>
              </div>
            </Alert>
          </div>
        </div>
      )}
    </>
  );
});

export default AdminUsersStatuses;
