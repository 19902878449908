import {Alert, Button, Tab} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react';
import React, {useEffect, useMemo, useState} from 'react';
import {Resource} from 'src/stores/domainObjects/Resource';
import {ConnectionServiceE, ResourceCategoryEnum} from 'src/types';
import {getResourcesTemplate, getResourceTemplateComponent} from '../../../connectors/manifests';
import TabHeader from './BranchComponents/TabHeader';
import TabSubHeader from './BranchComponents/TabSubHeader';
import {UploadModal} from 'src/components/MergeController/UploadModal';
import {CSVContent} from 'src/components/MergeController/helper';
import {SortState} from './shared';
import {useFeatureFlags} from 'src/lib';
import {TreeTabPanelT} from './TreeTabPanel';
import {useRootStore} from 'src/lib/hooks';
import {FileBasedSystemMetadata} from '../Create/GenericConnector';
import {Link} from 'react-router-dom';

export type SystemTreeTabPanelT = {
  filters: any;
  sortBy?: SortState;
  setFilters: (value: any) => void;
  visibleItems: {resources: string[]; permissions: string[]; accesses: string[]};
} & TreeTabPanelT;

const SystemTreeWrapper = observer((props: SystemTreeTabPanelT) => {
  const {
    user,
    system,
    canEditResource,
    prefilteredAccesses = [],
    hideRoots = true,
    showTabs = false,
    fixedFilters,
    visibleItems,
    filters,
    sortBy,
    hidePermissionsWithoutAccesses,
    setFilters,
    resources,
    disableFilters = false,
  } = props;

  const {currentUser} = useRootStore();
  const firstResource: Resource | undefined = system ?? _.first(resources);
  const template = getResourcesTemplate(firstResource?.type as string);
  const defaultOpened: string[] = disableFilters ? [] : (filters.Opened ?? '').split(',');

  const [showAlert, setShowAlert] = useState<boolean>(
    !currentUser.dismissedAlerts?.keys?.includes('addResourcesToGeneric')
  );
  const filteredIds = [...visibleItems.resources, ...visibleItems.permissions, ...visibleItems.accesses];
  const openedBranches = filters.Expand === 'All' || fixedFilters?.Expand === 'All' ? filteredIds : defaultOpened;
  const featureFlagViewer = useFeatureFlags();
  const [showResourceUploadModal, setShowResourceUploadModal] = useState<boolean>(false);
  const [showAccessUploadModal, setShowAccessUploadModal] = useState<boolean>(false);
  const [openedTab, setOpenedTab] = useState<number>(0);

  const getContent = (rs: Resource[]) => {
    const rootBranches = rs.map((targetResource) => {
      const ResourceCmp = getResourceTemplateComponent(targetResource);
      return (
        <>
          {showTabs && <TabSubHeader {...props} resource={rs[0]} filters={filters} />}

          <ResourceCmp.main
            {...{
              template: template,
              hideRoot: hideRoots,
              key: `${targetResource.id}-resource-branch`,
              isRootNode: true,
              accessMode: !canEditResource,
              resource: targetResource,
              isTreeView: true,
              opened: openedBranches,
              prefilteredAccesses,
              sort: sortBy,
              user: user,
              showTabs: showTabs,
              setFilters,
              filters,
              hidePermissionsWithoutAccesses,
              visibleItems: visibleItems,
            }}
          />
        </>
      );
    });

    return [rootBranches];
  };

  const enabledShowM365SitesOneDrive = featureFlagViewer.isEnabled('show_m365_sites_one_drive');
  const childResources = system ? [..._.orderBy(system.childResources, 'name', 'desc')] : resources;

  const tabResources = system && _.size(system?.permissions) > 0 ? [...childResources, system] : childResources;
  const conditionalFilterCondition = (res: Resource) => {
    if (res.type !== ConnectionServiceE.M365) {
      return true;
    }

    if (res.category !== ResourceCategoryEnum.SITES || enabledShowM365SitesOneDrive) {
      return true;
    }
    return false;
  };

  const tabs: any = _.sortBy(tabResources, ['name'], ['asc'])
    .filter((cr) => {
      return filteredIds.includes(cr.id) && conditionalFilterCondition(cr);
    })
    .map((cr) => {
      let filteredEntities = cr.childResourcesAndPerms.filter((subEntity) => {
        return filteredIds.includes(subEntity.id);
      });
      if (!cr.rootSid) {
        filteredEntities = cr.permissions.filter((subEntity) => {
          return filteredIds.includes(subEntity.id);
        });
      }

      const visibleEntities = _.size(filteredEntities);
      return {
        resource: cr,
        visible: visibleEntities,
        filtered: filteredEntities,
        mode: cr.id,
      };
    });

  const urlTabOpened = tabs.find((tab: any) => {
    return defaultOpened.includes(tab?.mode);
  });

  const filteredTabOpened = useMemo(() => {
    const hasVisibleItems = (tab: {visible: number}) => tab.visible > 0;
    const currentSelectedTab = tabs[openedTab];
    if (currentSelectedTab && hasVisibleItems(currentSelectedTab)) {
      return currentSelectedTab;
    }
    // First tab with visible items
    return tabs.find((tab: any) => {
      return hasVisibleItems(tab);
    });
  }, [openedTab, tabs]);

  const defaultSelection = !_.isEmpty(urlTabOpened)
    ? urlTabOpened
    : !_.isEmpty(filters) && !urlTabOpened
    ? filteredTabOpened
    : undefined;

  useEffect(() => {
    if (defaultSelection) {
      void setOpenedTab(_.findIndex(tabs, (cr: any) => cr.mode === defaultSelection.mode));
    }
  }, [defaultSelection]);

  const tabsCmpList = (
    <div className="tr-mt-3">
      {_.size(tabs) > 0 ? (
        <Tab.Group
          selectedIndex={openedTab}
          onChange={(i: number) => {
            const tempFilters = {...filters, ...fixedFilters};
            delete tempFilters.Opened;
            setFilters({...tempFilters, Opened: [tabs[i].resource.id]});
            setOpenedTab(i);
          }}
        >
          <Tab.List variant="line">
            {tabs.map((tab: any, index: number) => {
              const cr = tab.resource;
              const category = cr.category ?? ResourceCategoryEnum.RESOURCE;
              const selected = index === openedTab;
              return (
                <div key={`system-wrapper-tab-${index}`}>
                  <Tab
                    key={index}
                    as={'div'}
                    className={`tr-cursor-pointer tr-opacity-100  
                      ${!selected && 'tr-grayscale-60 tr-text-[#adadad] tr-font-medium'}`}
                    style={{paddingLeft: '0!important'}}
                  >
                    <TabHeader {...props} selected={selected} category={category} tab={tab} filters={filters} />
                  </Tab>
                </div>
              );
            })}
          </Tab.List>
          <Tab.Panels>
            {tabs.map((tab: any, index: number) => {
              const selected = index === openedTab;
              return (
                <Tab.Panel key={index}>
                  {selected ? (
                    <div className="tr-my-2 tr-mb-5 tr-pb-8" key={tab.resource.id}>
                      {[getContent([tab.resource])]}
                    </div>
                  ) : (
                    <></>
                  )}
                </Tab.Panel>
              );
            })}
          </Tab.Panels>
        </Tab.Group>
      ) : (
        <div className="text-center">
          <img className="tr-w-72" src="/russel-magnifying-glass.svg" alt="" />
          <h2 className="mt-0 text-xl text-center font-weight-bold text-dark">No Available Permissions Found</h2>
        </div>
      )}
    </div>
  );

  return (
    <div className="tr-mt-1">
      {canEditResource && system?.type === ConnectionServiceE.GENERIC && (
        <div className="tr-mt-4 tr-mb-5 tr-pb-2 tr-flex align-items-center tr-justify-end tr-w-full">
          <Button onClick={() => setShowResourceUploadModal(true)}>Upload Resources and Permissions</Button>
          {showAlert && (
            <div className="tr-flex tr-justify-end">
              <div className="tr-absolute tr-z-10 tr-ml-10 tr-mt-2">
                <Alert
                  isFloater={true}
                  title="Add Users and Permissions"
                  isBlinking={false}
                  size="md"
                  hideCloseButton={false}
                  onClose={() => {
                    void currentUser.disableAlert('addResourcesToGeneric');
                    setShowAlert(false);
                  }}
                >
                  <div className="flex">
                    <div className="flex-1 tr-mr-8">
                      You need to upload Resources, Permissions and Accesses to your newly created System. You can learn
                      more on
                      <Link to={FileBasedSystemMetadata.link!} target="_blank">
                        {` this guide`}
                      </Link>
                    </div>
                    <div className="flex-none" style={{marginTop: '-25px'}}></div>
                  </div>
                </Alert>
              </div>
            </div>
          )}
          {/*<Button onClick={() => setShowAccessUploadModal(true)}>Upload Accesses</Button>*/}
        </div>
      )}
      {showResourceUploadModal && (
        <UploadModal
          csvContent={CSVContent.RESOURCE_PERMISSION}
          resource={system}
          onClose={() => {
            setShowResourceUploadModal(false);
          }}
        />
      )}
      {showAccessUploadModal && (
        <UploadModal
          csvContent={CSVContent.ACCESS_RECORD}
          resource={system}
          onClose={() => {
            setShowAccessUploadModal(false);
          }}
        />
      )}
      {showTabs ? tabsCmpList : <div className="tr-py-3 tr-pb-5 tr-mt-3 tr-mb-10">{getContent(childResources)}</div>}
    </div>
  );
});

export default SystemTreeWrapper;
