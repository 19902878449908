import React, {useEffect} from 'react';
import {Link, Redirect, Route, Switch, useParams, useRouteMatch} from 'react-router-dom';
import {getListOfMails} from 'src/views/Resource/ResourceHeader';
import ResourceAccounts from 'src/views/Resource/Account/ResourceAccounts';
import {ImportStatus, ResourceTabMode} from '../types';
import PermissionDenied from 'src/components/navigation/PermissionDenied';
import ResourceSettingsBase from 'src/views/Resource/ResourceSettingsBase';
import {useQueryParams, useRootStore} from 'src/lib/hooks';
import {observer} from 'mobx-react';
import _ from 'lodash';
import AccountRecommendations, {Filter} from './Recommendations/AccountRecommendations/AccountRecommendations';
import MainPage from './Recommendations/MainPage';
import SystemRecommendations, {
  SystemRecommendationsTab,
} from './Recommendations/SystemRecommendations/SystemRecommendations';
import OverviewDashboard from 'src/views/Resource/Overview/OverviewDashboard';
import TreeTabPanel from 'src/views/Resource/TreeView/TreeTabPanel';
import {Alert, Loading, Tab, TileButton} from '@trustle/component-library';
import TreeTabHeader from 'src/views/Resource/TreeView/TreeTabHeader';
import {Resource} from 'src/stores/domainObjects/Resource';
import ConnectorErrorHeader from './Header/ConnectorErrorHeader';
import {SuggestAuthorityConfigOnboardModal} from '../components/modals/SuggestAuthorityConfigOnboardModal';
import {AuthorityBanner} from '../connectors/common/authority/banners/AuthorityBanner';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';
import OrgEvents from 'src/views/Events/OrgEvents';

const OwnersAndProvisioners = observer(({system}: {system: Resource}) => {
  const {users} = useRootStore().usersStore;

  return (
    <div className="tr-text-sm">
      <div className="tr-flex tr-justify-start tr-items-end">
        <span className="tr-mr-2">Owners: </span>
        {_.size(system.owners) > 1 ? getListOfMails(users, system.owners) : _.first(system.owners)}
      </div>
      <div className="tr-flex tr-justify-start tr-items-end">
        <span className="tr-mr-2">Provisioners: </span>
        {_.size(system.provisioners) > 1 ? getListOfMails(users, system.provisioners) : _.first(system.provisioners)}
      </div>
    </div>
  );
});

const ResourceBaseComponent = observer(function ResourceBase() {
  const {newResourceStore: resourceStore, currentUser} = useRootStore();
  const {rid} = useParams<{rid: string; activeMode: ResourceTabMode}>();
  const {path, params} = useRouteMatch<{activeMode?: string}>();
  const [isOnboarding] = useQueryParams(['onboard']);

  useEffect(() => {
    void resourceStore.loadDetailed(rid);
  }, []);

  const resource = resourceStore.resourceMap[rid];
  useEffect(() => {
    if (resource) {
      void resource.updateConnectionStatus();
    }
  }, [resource]);
  if (_.isNil(currentUser) || _.isNil(resource)) {
    return <Loading />;
  }
  const refresh = () => {
    void resourceStore.loadDetailed(rid, true);
  };

  const canEditResource = resource.userIsOwner;

  const canSeeResource = canEditResource || currentUser.isOrgOwner;

  const shouldDisplayAuthoritySetupModal = Boolean(isOnboarding) && resource.idpFeatureEnabled;

  const tabs = [
    'overview',
    'recommendations',
    'resources',
    'accounts',
    'history',
    ...(canSeeResource ? ['settings'] : []),
  ];
  return (
    <>
      {shouldDisplayAuthoritySetupModal && <SuggestAuthorityConfigOnboardModal resource={resource} />}
      <Tab.Group selectedIndex={tabs.indexOf(params.activeMode ?? 'overview') ?? 0}>
        <ConnectorErrorHeader resource={resource} />
        <AuthorityBanner resource={resource} />
        <div className="from-sensitivity-blue tr-pt-4 tr-text-white tr-bg-trustle-royal">
          <div className="tr-flex tr-items-center tr-my-4 container">
            <TileButton name={resource.type! as SystemIconsTypesEnum} size="md" />
            <TreeTabHeader system={resource} />
            <OwnersAndProvisioners system={resource} />
          </div>
          <Tab.List variant="folder" className="container">
            {tabs.map((t) => (
              <Tab
                data-testid={`tab-${t}`}
                key={t}
                as={Link}
                to={`/resource/manage/${resource.id}/${t}`}
                className="aria-selected:tr-bg-white aria-selected:hover:tr-text-blue hover:tr-text-white hover:tr-no-underline tr-opacity-100"
              >
                {_.upperFirst(t)}
              </Tab>
            ))}
          </Tab.List>
        </div>
        {resource.importStatus === ImportStatus.PENDING && (
          <div className="container tr-py-2">
            <Alert colorVariant="primary" title="Importing data">
              Data import from <strong>{resource.name}</strong> is running. You can monitor its progress from the system
              setting screen under connection settings tab.
            </Alert>
          </div>
        )}
        <Switch>
          <Redirect
            exact={true}
            from={`/resource/manage/${resource.id}`}
            to={isOnboarding ? `./${resource.id}/overview?onboard=true` : `./${resource.id}/overview`}
          />
          {canSeeResource === false && <Route render={() => <PermissionDenied />} />}
          <Route path={`${path}/overview/:manage?`} render={() => <OverviewDashboard resource={resource} />} />
          <Route path={`${path}/recommendations`}>
            <Switch>
              <Route path={`${path}/accounts/:tab`}>
                <AccountRecommendations system={resource} />
              </Route>
              <Route exact path={`${path}/accounts`}>
                <Redirect to={`${path}/recommendations/accounts/${Filter.ACCOUNTS_DETECTED}`} />
              </Route>
              <Route exact path={`${path}/recommendations/accounts`}>
                <Redirect to={`${path}/recommendations/accounts/${Filter.ACCOUNTS_DETECTED}`} />
              </Route>
              <Route path={`${path}/system/:tab`}>
                <SystemRecommendations system={resource} />
              </Route>
              <Route exact path={`${path}/system`}>
                <Redirect to={`${path}/recommendations/system/${SystemRecommendationsTab.SYSTEM_RECOMMENDATIONS}`} />
              </Route>
              <Route>
                <MainPage system={resource} />
              </Route>
            </Switch>
          </Route>
          <Route
            path={`${path}/resources/:activeTab?`}
            render={() => (
              <div className="container">
                <TreeTabPanel
                  system={resource}
                  showTabs={true}
                  resources={[resource]}
                  canEditResource={canEditResource}
                  showInactiveSystemFilter={true}
                />
              </div>
            )}
          />
          <Route
            path={`${path}/accounts/:tab?`}
            render={() => <ResourceAccounts refresh={refresh} resource={resource} />}
          />
          <Route
            path={`${path}/history`}
            exact={true}
            render={() => (
              <div className="container tr-mt-4">
                <h1 className="mt-2">History</h1>
                <OrgEvents entityType={'resources'} id={resource.id} />
              </div>
            )}
          />
          <Redirect exact={true} from={`${path}/settings`} to={`${path}/settings/edit`} />
          <Route path={`/resource/manage/:resourceId/${ResourceTabMode.settings}/:activeTab`} key="settings">
            {canSeeResource ? (
              <ResourceSettingsBase resource={resource} canEditResource={canEditResource} refresh={refresh} />
            ) : (
              <Redirect to={`/resource/manage/${resource.id}/${ResourceTabMode.overview}`} />
            )}
          </Route>
        </Switch>
      </Tab.Group>
    </>
  );
});

export default ResourceBaseComponent;
