import React from 'react';
import {formatDateForLegend, resolveFillColor} from '../utils';
import {getLicenseName} from '../../../constants';

export function CustomTooltip({active, payload, label}: any) {
  if (active && payload && payload.length) {
    const data = Object.values(payload[0].payload.data);
    return (
      <div className="tr-bg-[#F8F9FC] tr-px-4 tr-py-2">
        <p className="tr-text-sm tr-text-trustle-primary">{`Usage ${formatDateForLegend(label)}`}</p>
        <div className="tr-flex tr-flex-col tr-gap-2">
          {data.map((item: any) => (
            <span key={item.license} className="tr-flex tr-justify-between tr-gap-2">
              <span style={{color: resolveFillColor(item.license)}}>{getLicenseName(item.license)}:</span>
              <span>{Math.round((item.sum_of_usage_hours / item.sum_of_granted_hours) * 100)}%</span>
            </span>
          ))}
        </div>
      </div>
    );
  }

  return null;
}
