import {useEffect, useState} from 'react';
import axios from 'axios';

type Props = {
  resourceId: string;
};

export function useResourceFirstSuccessImport({resourceId}: Props) {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchResourceFirstSuccessImport = async () => {
    const BASE_URL = `/api/resources/${resourceId}/first_import`;
    try {
      const response = await axios.get<any>(BASE_URL);
      const responseData = response.data;
      setData(responseData);
    } catch (err: any) {
      // TODO: Implement handling error
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    void fetchResourceFirstSuccessImport();
  }, []);

  return {data, loading, error};
}
