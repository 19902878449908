import React from 'react';
import _ from 'lodash';
import AccessDetail from './AccessDetail';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import {Modal, Tab} from '@trustle/component-library';
import OrgEvents from 'src/views/Events/OrgEvents';

type AccessDetailModalProps = {
  closeModal: any;
  visible: boolean;
  access?: AccessRecord;
};

enum TabModeE {
  GENERAL = 'general',
  HISTORY = 'history',
}

function AccessDetailModal(props: AccessDetailModalProps) {
  const {closeModal, access, visible} = props;

  if (!access) {
    return <></>;
  }
  return (
    <Modal
      onClose={closeModal}
      width="lg"
      height="md"
      visible={visible}
      title={`${access.forAccount?.account} Details`}
    >
      <Tab.Group className="m-2">
        <Tab.List variant="line">
          <Tab data-testid={TabModeE.GENERAL} key={TabModeE.GENERAL}>
            {_.startCase(TabModeE.GENERAL)}
          </Tab>
          <Tab data-testid={TabModeE.HISTORY} key={TabModeE.HISTORY}>
            {_.startCase(TabModeE.HISTORY)}
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel key={TabModeE.GENERAL}>
            <AccessDetail access={access} />
          </Tab.Panel>
          <Tab.Panel key={TabModeE.HISTORY}>
            <div className="history-tab tr-flex tr-flex-col">
              <div className="flex-align tr-justify-between tr-items-center generate-report-button-wrapper">
                <label className="body3">Show all history</label>
              </div>
              <OrgEvents entityType={'access'} id={access.id} />
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Modal>
  );
}

export default AccessDetailModal;
