import {Button} from '@trustle/component-library';
import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useRootStore} from 'src/lib/hooks';
import OnboardingWrapper from './OnboardingWrapper';

function SetupComplete() {
  const {org, currentUser} = useRootStore();
  const history = useHistory();

  const isOriginalOrgOwner = currentUser.isOrgOwner && currentUser.invitedAt === undefined;

  const title = 'Organization is setup';

  //TODO: check this and remove if unnecessary in new login flow
  useEffect(() => {
    if (org && !org.isSetup) {
      void org?.markSetupComplete();
    }
  }, [org]);

  return (
    <OnboardingWrapper>
      <h4 className="card-title form-title">{title}</h4>
      <div className="card-text">
        <p>Congratulations! Let's manage access.</p>
        <Button
          variant="primary"
          onClick={() => (isOriginalOrgOwner ? history.push('/admin/setup') : history.push('/home'))}
        >
          Finish
        </Button>
      </div>
    </OnboardingWrapper>
  );
}

export default SetupComplete;
