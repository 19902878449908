import React, {useState} from 'react';
import {Link, Route, Switch, useParams} from 'react-router-dom';
import EditConnection from 'src/connectors/common/editConnection/EditConnection';
import ResourceForm from 'src/views/Resource/Edit/ResourceForm';
import {ConnectionServiceE} from 'src/types';
import SettingsHeader from './SettingsHeader';
import './ResourceSettingsBase.scss';
import OnCallSettings from './OnCall/OnCallSettings';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Button, Icon, Tab} from '@trustle/component-library';
import {useFeatureFlags} from 'src/lib';
import {AuthoritySettings} from '../../connectors/common/authority/authority-settings/AuthoritySettings';
import _ from 'lodash';

export enum ResourceSettingsTabs {
  EDIT = 'edit',
  CONNECTION_EDIT = 'edit_connection',
  AUTHORITY_SETTINGS = 'authority_settings',
  ONCALL = 'oncall',
}

type ResourceSettingsPropsT = {
  resource: Resource;
  canEditResource?: boolean;
  refresh: () => void;
};

function ResourceSettings(props: ResourceSettingsPropsT) {
  const {resource, refresh, canEditResource} = props;
  const featureFlagViewer = useFeatureFlags();

  const routeParams = useParams() as {activeTab?: ResourceSettingsTabs};
  const tabs = _.values(ResourceSettingsTabs);
  const activeTab = _.findIndex(tabs, (tab) => {
    return tab === routeParams.activeTab;
  });
  const [selectedIndex, setSelectedIndex] = useState<number>(activeTab);

  const displayAuthoritySettings = resource.idpFeatureEnabled;

  const {connector} = resource;
  function renderHeader(): React.ReactNode {
    const documentationLink = connector
      ? {
          [ConnectionServiceE.AZURE_AD]: '/docs/connectors/azure_ad/configure_azure_ad',
          [ConnectionServiceE.AWS]: '/docs/connectors/aws/configure_aws',
          [ConnectionServiceE.GAPPS]: '/docs/connectors/gapps/configure_gapps',
          [ConnectionServiceE.GITHUB]: null,
          [ConnectionServiceE.GENERIC]: '/docs/connectors/universal/csv_guide',
          [ConnectionServiceE.OKTA]: '/docs/connectors/okta/okta_guide',
          [ConnectionServiceE.SLACK]: null,
          [ConnectionServiceE.PAGERDUTY]: '/docs/connectors/pagerduty/configure_pagerduty',
          [ConnectionServiceE.STRONGDM]: null,
          [ConnectionServiceE.GCP]: '/docs/connectors/gcp/configure_gcp',
          [ConnectionServiceE.CLOUDFLARE]: null,
          [ConnectionServiceE.CUSTOM]: null,
          [ConnectionServiceE.CUSTOM_SYSTEM]: null,
          [ConnectionServiceE.JIRA]: null,
          [ConnectionServiceE.TABLEAU]: '/docs/connectors/tableau_setup',
          [ConnectionServiceE.M365]: '/docs/connectors/m365/configure_m365',
          [ConnectionServiceE.AWS_IDENTITY_CENTER]:
            '/docs/connectors/aws_identity_center/configure_aws_identity_center',
        }[connector?.service]
      : null;
    const actionItems = documentationLink
      ? [
          <Button
            key={'documentationLink'}
            variant="ternary"
            onClick={() => {
              window.open(documentationLink, '_blank', 'rel=noreferrer');
            }}
          >
            <div className="flex tr-justify-center items-center">
              <span className="tr-ml-1 ">Documentation</span>
              <Icon type="external" title="documentation" />
            </div>
          </Button>,
        ]
      : undefined;

    return (
      <SettingsHeader
        canEditResource={canEditResource}
        refresh={() => {
          refresh();
        }}
        actionItems={actionItems}
        resource={resource}
      />
    );
  }

  return (
    <div className="resource-settings container">
      {renderHeader()}
      <div className="py-3">
        <Tab.Group selectedIndex={selectedIndex} onChange={(i: number) => setSelectedIndex(i)}>
          <Tab.List valiant="line" className="border-bottom">
            <Tab
              as={Link}
              to={`/resource/manage/${resource.id}/settings/${ResourceSettingsTabs.EDIT}`}
              key={ResourceSettingsTabs.EDIT}
            >{`System Settings`}</Tab>
            {connector && connector.service !== ConnectionServiceE.GENERIC && (
              <Tab
                as={Link}
                to={`/resource/manage/${resource.id}/settings/${ResourceSettingsTabs.CONNECTION_EDIT}`}
                key={ResourceSettingsTabs.CONNECTION_EDIT}
              >{`Connection Settings`}</Tab>
            )}
            {displayAuthoritySettings && (
              <Tab
                key={ResourceSettingsTabs.AUTHORITY_SETTINGS}
                as={Link}
                to={`/resource/manage/${resource.id}/settings/${ResourceSettingsTabs.AUTHORITY_SETTINGS}`}
              >
                {'Authority Settings'}
              </Tab>
            )}
            {!featureFlagViewer.isEnabled('disable_oncall') && ConnectionServiceE.GENERIC !== connector?.service && (
              <Tab
                key={ResourceSettingsTabs.ONCALL}
                as={Link}
                to={`/resource/manage/${resource.id}/settings/${ResourceSettingsTabs.ONCALL}`}
              >
                {'On-Call'}
              </Tab>
            )}
          </Tab.List>
        </Tab.Group>
        <Switch>
          <Route path={`/resource/manage/${resource.id}/settings/${ResourceSettingsTabs.EDIT}`} exact={true}>
            <ResourceForm resource={resource} isCreating={false} />
          </Route>
          <Route path={`/resource/manage/${resource.id}/settings/${ResourceSettingsTabs.CONNECTION_EDIT}`} exact={true}>
            <EditConnection resource={resource} />
          </Route>
          {displayAuthoritySettings && (
            <Route
              path={`/resource/manage/${resource.id}/settings/${ResourceSettingsTabs.AUTHORITY_SETTINGS}`}
              exact={true}
            >
              <AuthoritySettings resource={resource} />
            </Route>
          )}
          {!featureFlagViewer.isEnabled('disable_oncall') && (
            <Route path={`/resource/manage/${resource.id}/settings/${ResourceSettingsTabs.ONCALL}`} exact={true}>
              <OnCallSettings id={resource.id} />
            </Route>
          )}
        </Switch>
      </div>
    </div>
  );
}

export default ResourceSettings;
