import {Alert, Icon} from '@trustle/component-library';
import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Resource} from 'src/stores/domainObjects/Resource';
import {ConnectionServiceE, MessageTypeE, getConnectionServiceName} from 'src/types';

const ErrorHeader = (props: {resource: Resource}) => {
  const location = useLocation();

  const {resource} = props;
  const {connector} = resource;

  return (
    <Alert
      title="Broken Connection"
      colorVariant={MessageTypeE.DANGER}
      icon={<Icon type="unlinkedUser" style={{color: 'rgb(153 27 27)'}} />}
    >
      <div>
        {connector!.service === ConnectionServiceE.GENERIC ? (
          'A problem ocurred while importing the uploaded data.'
        ) : (
          <div>
            {`Trustle cannot connect to your ${getConnectionServiceName(connector!.service)} account. `}
            {location.pathname !== `/resource/manage/${resource.id}/settings/edit_connection` ? (
              <span>
                {'Please, check your '}
                <Link className="link" to={`/resource/manage/${resource.id}/settings/edit_connection`}>
                  Connection Settings
                </Link>
                .
              </span>
            ) : (
              <span>Please, validate your credentials.</span>
            )}
          </div>
        )}
      </div>
    </Alert>
  );
};

export default ErrorHeader;
