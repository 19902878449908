import React, {useState} from 'react';
import _ from 'lodash';

import {useHistory} from 'react-router-dom';
import {useQueryParams} from 'src/lib';
import {
  ConnectionServiceE,
  FacetIdEnum,
  getConnectionService,
  getIntegrationService,
  IntegrationService,
} from 'src/types';
import {Button, Confirmation, Modal, TileButton, Tooltip} from '@trustle/component-library';

import {UserAction} from 'src/stores/usersStore';
import {useRootStore} from 'src/lib/hooks';
import MergeManager from '../MergeManager';
import {useAuthority} from '../../connectors/common/authority/hooks/useAuthority';
import {SyncUsersModal} from '../../connectors/common/authority/modals/sync-users-modal/SyncUsersModal';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';

function UsersImport({invertColors = false}: {invertColors?: boolean}) {
  const {usersStore, org, newResourceStore: resourceStore} = useRootStore();
  const {resource: authorityResource} = useAuthority();

  const history = useHistory();

  const isFreePlan = org.facetId === FacetIdEnum.FREE_AWS;
  const orgResources = resourceStore.resourceMap;
  const [importing] = useQueryParams(['importing']);
  const [modal, setModal] = useState<string | null>(importing ? UserAction.UPLOAD_CSV : null);
  const [modalTitle, setModalTitle] = useState<string>('Import Users');
  const [nextURL, setNextURL] = useState<string | undefined>(undefined);
  const [compatibleConnectionName, setCompatibleConnectionName] = useState<string | undefined>(undefined);
  const [integrationSelected, setIntegrationSelected] = useState<string | undefined>(undefined);

  const refresh = () => {
    void usersStore.refresh();
    setModal(null);

    if (usersStore.error) {
      usersStore.rootStore.toast.add('Failed to load users.', {appearance: 'error', autoDismiss: true});
    }
  };

  type IDPButtonT = {
    service: ConnectionServiceE | undefined;
    setCompatibleConnectionName: any;
  };

  const IDPButton = (props: IDPButtonT) => {
    const {service, setCompatibleConnectionName} = props;

    if (!service || !org.isIDPFeatureEnabled(service)) {
      return <></>;
    }

    const integration = getIntegrationService(service);

    const configuredIDP = org.idpSettings?.service;
    const disable = !_.isNil(configuredIDP) && configuredIDP !== integration;

    const idpButton = (
      <TileButton
        className={disable ? 'opacity-50 bg-gray-300 tr-cursor-not-allowed' : ''}
        key={`${integration}-idp-import`}
        size="md"
        onClick={() => {
          if (configuredIDP === integration) {
            // if IDP is already enabled and configured, just open the user candidates modal
            setModal(UserAction.IDP_SYNC);
          } else {
            // if no IDP configred but there's an existent connection that supports IDP enablement, just ask to configure it
            // else prompt to configure a new compatible connector

            const compatibleConnectionId = _.find(_.keys(orgResources), (rid) => {
              const {connector} = orgResources[rid];
              return connector?.service === service;
            });

            if (compatibleConnectionId) {
              const settingTab = 'authority_settings';
              setNextURL(`/resource/manage/${compatibleConnectionId}/settings/${settingTab}`);
              setModal(UserAction.IDP_SETUP);
              const compatibleName = orgResources[compatibleConnectionId].name;
              setCompatibleConnectionName(compatibleName);
            } else {
              setNextURL(`/connect/${service}`);
              setModal(UserAction.IDP_SETUP);
              setIntegrationSelected(integration);
            }
          }
        }}
        disabled={disable}
        title={`${_.startCase(integration)} IDP Import`}
        name={service as SystemIconsTypesEnum}
      />
    );

    return disable ? (
      <Tooltip content={'Only a single IDP instance can be enabled at the same time.'} position="bottom" size="lg">
        {idpButton}
      </Tooltip>
    ) : (
      idpButton
    );
  };

  const AvailableIDPButtons = () => {
    return (
      <>
        {_.map(_.values(IntegrationService), (integration) => {
          return (
            <IDPButton
              service={getConnectionService(integration)}
              setCompatibleConnectionName={setCompatibleConnectionName}
            />
          );
        })}
      </>
    );
  };

  const idpCount = _.size(_.values(IntegrationService));

  return (
    <>
      <Button variant={invertColors ? 'inverted' : 'secondary'} onClick={() => setModal(UserAction.SYNC_USERS)}>
        Import Users
      </Button>
      {modal === UserAction.SYNC_USERS && (
        <Modal
          width={`${idpCount > 2 ? 'xl' : idpCount > 1 ? 'lg' : 'md'}`}
          title={'Import Users'}
          onClose={() => setModal(null)}
        >
          <div>
            <div className="tr-flex flex-row tr-justify-center tr-items-center">
              <TileButton
                key={'csv-import'}
                name={'generic'}
                title={'CSV Import'}
                onClick={() => setModal(UserAction.UPLOAD_CSV)}
              />
              <AvailableIDPButtons />
            </div>
          </div>
        </Modal>
      )}
      {modal === UserAction.UPLOAD_CSV && (
        <>
          {isFreePlan && org.billableUsersCount >= 5 ? (
            <Confirmation
              onConfirm={() => {
                history.push('/admin/billing/plan');
              }}
              onClose={() => {
                setModal(null);
              }}
              title="Upgrade to add another user"
              confirmButtonLabel="Upgrade"
            >
              {'To add another user, please upgrade to Teams version. You will get a free 14-day trial'}
            </Confirmation>
          ) : (
            <Modal width={'xl'} onClose={refresh} visible={modal === UserAction.UPLOAD_CSV} title={modalTitle}>
              <a
                href={'/docs/admin/users/csv_guide'}
                rel="noopener noreferrer"
                target="_blank"
                className="text-underline"
              >
                <p style={{fontSize: 'small'}}>Documentation</p>
              </a>
              <MergeManager setModalTitle={setModalTitle} onClose={refresh} />
            </Modal>
          )}
        </>
      )}
      {modal === UserAction.IDP_SYNC && authorityResource && (
        <SyncUsersModal
          resource={authorityResource}
          setShowModal={() => {
            setModal(null);
          }}
        />
      )}
      {modal === UserAction.IDP_SETUP && nextURL && (
        <div className="tr-text-trustle-dark">
          {_.startsWith(nextURL, '/connect') ? (
            <Confirmation
              onConfirm={async () => history.push(nextURL)}
              onClose={() => setModal(null)}
              title={'Confirm action'}
            >
              <span>
                To enable importing users automatically from <strong>{integrationSelected}</strong>, you must first
                install it’s associated integration, and select “Enable User Syncing” from the connection settings tab.
                Trustle will now take you to the add <strong>{integrationSelected}</strong> integration page, where you
                can create the integration, and enable user sync. Once complete, come back to the User Management page,
                and select the Import Users button to continue`
              </span>
            </Confirmation>
          ) : (
            <Confirmation
              onConfirm={async () => history.push(nextURL)}
              onClose={() => setModal(null)}
              title={'Confirm action'}
            >
              <span>
                Trustle will now take you to the <strong>{compatibleConnectionName}</strong> connection settings page
                where you must select "Enable User Syncing" and then click "Save". Once complete, come back to the User
                Management page, and select the Import Users button to continue.
              </span>
            </Confirmation>
          )}
        </div>
      )}
    </>
  );
}

export default UsersImport;
