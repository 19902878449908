import React, {useState} from 'react';
import SensitivitySettings from './sensitivity/SensitivitySettings';
import {Link, useParams} from 'react-router-dom';
import AuthenticationSettings from './authSettings/AuthenticationSettings';
import AdminCompliance from './AdminCompliance';
import OnCallSetup from './onCall/OnCallSetup';
import {Tab} from '@trustle/component-library';
import {useFeatureFlags} from 'src/lib';
import _ from 'lodash';

enum SecurityTabsE {
  AUTH = 'authentication',
  SENSITIVITY = 'sensitivity',
  COMPLIANCE = 'compliance',
  ONCALL = 'oncall',
}

function AdminSecurity() {
  const routeParams = useParams() as {activeTab?: SecurityTabsE};
  const featureFlagViewer = useFeatureFlags();
  const orgEventsReportsHidden = featureFlagViewer.isEnabled('hide_reports_until_peryton_events_stable');
  const activeTab = routeParams.activeTab ?? SecurityTabsE.AUTH;
  const tabs = [SecurityTabsE.AUTH, SecurityTabsE.SENSITIVITY, SecurityTabsE.COMPLIANCE, SecurityTabsE.ONCALL];
  const [selectedIndex, setSelectedIndex] = useState<number>(
    _.findIndex(tabs, (tab) => {
      return tab === activeTab;
    }) ?? 0
  );

  return (
    <div className={"tr-mb-10"}>
      <h3>Security Settings</h3>
      <Tab.Group selectedIndex={selectedIndex} onChange={(i: number) => setSelectedIndex(i)}>
        <Tab.List variant="line">
          <Tab as={Link} to={'/admin/security'} data-testid={SecurityTabsE.AUTH} key={SecurityTabsE.AUTH}>
            {`Authentication`}
          </Tab>
          <Tab
            as={Link}
            to={`/admin/security/${SecurityTabsE.SENSITIVITY}`}
            data-testid={SecurityTabsE.SENSITIVITY}
            key={SecurityTabsE.SENSITIVITY}
          >
            {`Sensitivity Score`}
          </Tab>
          {!orgEventsReportsHidden && (<Tab
            as={Link}
            to={`/admin/security/${SecurityTabsE.COMPLIANCE}`}
            data-testid={SecurityTabsE.COMPLIANCE}
            key={SecurityTabsE.COMPLIANCE}
          >
            {'Compliance Reports'}
          </Tab>)}
          {!featureFlagViewer.isEnabled('disable_oncall') && (
            <Tab
              as={Link}
              to={`/admin/security/${SecurityTabsE.ONCALL}`}
              data-testid={SecurityTabsE.ONCALL}
              key={SecurityTabsE.ONCALL}
            >
              {'On-Call Users'}
            </Tab>
          )}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel key={SecurityTabsE.AUTH}>
            <AuthenticationSettings />
          </Tab.Panel>
          <Tab.Panel key={SecurityTabsE.SENSITIVITY}>
            <SensitivitySettings />
          </Tab.Panel>
          {!orgEventsReportsHidden && (
            <Tab.Panel key={SecurityTabsE.COMPLIANCE}>
            <AdminCompliance />
            </Tab.Panel>
          )}
          <Tab.Panel key={SecurityTabsE.ONCALL}>
            <OnCallSetup />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

export default AdminSecurity;
