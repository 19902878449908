import React, {useEffect, useState} from 'react';
import {Accordion, Icon, Loading, Tab} from '@trustle/component-library';
import {formatUserName, fuseSearch} from 'src/lib';
import {useQueryStrings, useRootStore} from 'src/lib/hooks';
import {observer} from 'mobx-react';
import {Team} from 'src/stores/domainObjects/Team';
import {User} from 'src/stores/domainObjects/User';
import AdvancedFilters from './filters/AdvancedFilters';
import UsersTable from './users-table/UsersTableCols';
import {UserStatus} from 'src/stores/usersStore';
import AddUserButton from 'src/components/modals/AddUserButton';
import UsersImport from '../UsersImport';
import moment from 'moment';
import NewUsersConfirmationModal from './NewUsersConfirmationModal';
import {Link, useParams} from 'react-router-dom';
import _ from 'lodash';
import LensFilters from './filters/LensFilters';
import UsersTypeFilters from './filters/UsersTypeFilters';
import {SystemCounts} from '@trustle/component-library/dist/molecules/SystemsModal/SystemsAccordion';
import {AuthoritySelector} from '../../../connectors/common/authority/authority-selector/AuthoritySelector';
import {OffboardInactiveUsersButton} from './OffboardInactiveUsersButton';

export type FilteredByT = {
  roles: Record<string, User[]>;
  departments: Record<string, User[]>;
  titles: Record<string, User[]>;
  teams: Record<string, {team: Team; members: User[]}>;
  systems: Record<string, User[] | null>;
};

const AdminUsersStatuses = observer(function AdminUsersCmp() {
  const {usersStore, teamStore, org} = useRootStore();
  const {newResourceStore: resourceStore} = useRootStore();
  const {teams} = teamStore;
  const routeParams = useParams() as {activeTab?: UserStatus};
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const [filters, setFilters] = useQueryStrings({});

  const [query, setQuery] = useState('');
  const [showLensFilters, setShowLensFilters] = useState<{active: keyof FilteredByT | null}>({active: null});

  const {users, loading} = usersStore;

  const tabs = [
    UserStatus.ACTIVE,
    UserStatus.PENDING_APPROVAL,
    UserStatus.DISABLED,
    UserStatus.OFFBOARDED,
    UserStatus.INVALID,
  ];

  const [invalidUsers, restOfUsers] = _.partition(users, (user) => {
    return !user.isValidForAuthorityMapping;
  });

  const presentStatuses = _.groupBy(restOfUsers, (user) => user.status);

  if (invalidUsers.length) {
    presentStatuses[UserStatus.INVALID] = invalidUsers;
  }

  const activeTabs = tabs.filter((tab) => {
    return presentStatuses[tab];
  });

  useEffect(() => {
    const tabIndex = activeTabs.indexOf(routeParams.activeTab || UserStatus.ACTIVE);
    setSelectedIndex(Math.max(tabIndex, 0));
  }, [routeParams.activeTab, users]);

  const filteredUsers = presentStatuses[activeTabs[selectedIndex]];

  /* Filtering records depending on selected option or query */
  const filteredRecords = _.filter(
    filteredUsers,
    (user: User) =>
      (_.isNil(filters['Trustle User Type']) ||
        [undefined, ...filters['Trustle User Type'].split(',')].includes(user.type)) &&
      (_.isNil(filters.Role) || [undefined, ...filters.Role.split(',')].includes(user.remoteRole)) &&
      (_.isNil(filters.Title) || [undefined, ...filters.Title.split(',')].includes(user.title ?? '')) &&
      (_.isNil(filters.Department) || [undefined, ...filters.Department.split(',')].includes(user.department)) &&
      (_.isNil(filters.Team) || !!_.find(teams, (team) => _.map(team.members, 'id').includes(user.id))) &&
      (_.isNil(filters.LinkStatus) ||
        (filters.LinkStatus === 'Unlinked' && _.isNil(org.accountsDictionary[user.id]))) &&
      (_.isNil(filters.System) || user.resourcesNames.includes(filters.System)) &&
      (_.isNil(filters.Invited) ||
        (filters.Invited === 'Expired' && moment().diff(moment(user.invitedAt), 'days') > 1) ||
        (filters.Invited === 'Pending' && !user.invitedAt) ||
        (filters.Invited === 'Sent' && moment().diff(moment(user.invitedAt), 'days') <= 1))
  );

  const searchedAndFilteredRecords = fuseSearch(
    query,
    ['email', 'firstname', 'lastname', 'manager.email', 'manager.firstname', 'manager.lastname'],
    filteredRecords
  );

  const groupByProperty = (filteredRecords: User[], property: string) => _.groupBy(filteredRecords, property);

  const groupByTeamsName = (filteredRecords: User[], teams: Team[]) => {
    const filtered = _.keyBy(
      _.map(teams, (team: Team) => ({
        members: _.filter(filteredRecords, (user: User) => team.memberIds.includes(user?.id)),
        team,
      })),
      (item) => item.team.name
    );
    return filtered;
  };

  const groupBySystems = (filteredRecords: User[]) => {
    const countedResources = _.countBy(resourceStore?.systems, 'name') as unknown as SystemCounts;
    const filteredBySystems = _.mapValues(countedResources, (_count: number, systemName: string) =>
      _.filter(filteredRecords, (user: User) => user.resourcesNames.includes(systemName))
    );
    const filtered = _.pickBy(filteredBySystems, (users) => !_.isEmpty(users));
    return filtered as unknown as Record<string, User[]>;
  };

  const filteredBy: FilteredByT = {
    roles: groupByProperty(filteredRecords, 'remoteRole'),
    departments: groupByProperty(filteredRecords, 'department'),
    titles: groupByProperty(filteredRecords, 'title'),
    teams: groupByTeamsName(filteredRecords, teams),
    systems: groupBySystems(filteredRecords),
  };

  interface LensTableViewProps {
    usersBy: any;
  }

  interface LensTableTeamsProps {
    teams: any;
  }

  const LensTableView: React.FC<LensTableViewProps> = observer(({usersBy}) => {
    return (
      <>
        {_.map(usersBy, (users: User[], key) => {
          const totalPermissionsUsersBy = _.sumBy(users, (user: User) => user.nonPlaceholderAccesses.length);
          const totalResourcesBy = _.sumBy(users, (user: User) => user.resourcesNames.length);
          const noRecordsFound = `[No ${showLensFilters.active} defined]`;
          const iconsTable = {
            roles: 'role',
            departments: 'project',
            titles: 'project',
            teams: 'teams',
            systems: 'system',
          };
          const iconShow: any = showLensFilters.active ? iconsTable[showLensFilters.active] : 'role';

          const accordionTitle = (
            <div className="tr-flex tr-py-2 tr-pl-2">
              <div className="tr-flex tr-items-center tr-w-40">
                <Icon type={iconShow} className="tr-pr-2" />
                <div className="tr-flex tr-text-sm tr-font-bold tr-text-navy">
                  {key === 'null' ? noRecordsFound : key}
                </div>
              </div>
              <div className="tr-flex tr-ml-8 tr-items-center">
                <Icon type="role" className="tr-pr-2" />
                <div className="tr-flex tr-text-sm tr-font-bold">{users?.length}</div>
              </div>
              <div className="tr-flex tr-ml-8 tr-items-center">
                <Icon type="permission" className="tr-pr-2" />
                <div className="tr-flex tr-text-sm tr-font-bold">{totalResourcesBy}</div>
              </div>
              <div className="tr-flex tr-ml-8 tr-items-center">
                <Icon type="access" className="tr-pr-2" />
                <div className="tr-flex tr-text-sm tr-font-bold">{totalPermissionsUsersBy}</div>
              </div>
            </div>
          );
          return (
            <Accordion content={accordionTitle} variant="primary">
              {() => {
                return <UsersTable users={users} tableKey={activeTabs[selectedIndex]} />;
              }}
            </Accordion>
          );
        })}
      </>
    );
  });

  const LensTableTeams: React.FC<LensTableViewProps> = observer(({usersBy}) => {
    return (
      <>
        {_.map(
          _.filter(usersBy, (teams: Record<string, {team: Team; members: User[]}>) => !_.isEmpty(teams.members)),
          (teams, key: any) => {
            const managersNames = _.map(teams.team.managers, (m) => formatUserName(m)).join(', ');
            const totalPermissionsUsersBy = _.sumBy(teams.members, (user: User) => user.nonPlaceholderAccesses.length);
            const totalResourcesBy = _.sumBy(teams.members, (user: User) => user.resourcesNames.length);
            const noRecordsFound = `[No ${showLensFilters.active} defined]`;
            const totalUsers = teams.members.length;

            const accordionTitle = (
              <div className="tr-flex tr-py-2 tr-items-center">
                <Icon type="team" className="tr-pl-2" />
                <div className="tr-flex tr-flex-col tr-pl-4">
                  <div className="tr-flex tr-items-center tr-w-52">
                    <div className="tr-flex tr-text-xs tr-font-bold tr-pb-1 tr-text-navy">
                      {key === 'null' ? noRecordsFound : teams.team.name}
                    </div>
                  </div>
                  <div className="tr-flex">
                    <div className="tr-flex  tr-items-center">
                      <Icon type="role" className="tr-pr-1" size="sm" />
                      <div className="tr-flex tr-text-xs tr-font-bold">{totalUsers}</div>
                    </div>
                    <div className="tr-flex tr-ml-2 tr-items-center">
                      <Icon type="permission" className="tr-pr-1" size="sm" />
                      <div className="tr-flex tr-text-xs tr-font-bold">{totalResourcesBy}</div>
                    </div>
                    <div className="tr-flex tr-ml-2 tr-items-center">
                      <Icon type="access" className="tr-pr-1" size="sm" />
                      <div className="tr-flex tr-text-xs tr-font-bold">{totalPermissionsUsersBy}</div>
                    </div>
                  </div>
                </div>
                <div className="tr-flex tr-text-sm tr-font-bold">
                  {`Manager${managersNames.length > 1 ? 's' : ''}: ${managersNames}`}
                </div>
              </div>
            );
            return (
              <Accordion content={accordionTitle} variant="primary">
                {() => {
                  return <UsersTable users={teams.members} tableKey={activeTabs[selectedIndex]} />;
                }}
              </Accordion>
            );
          }
        )}
      </>
    );
  });

  interface LensTableViews {
    [key: string]: React.FC<LensTableViewProps> | React.FC<LensTableTeamsProps>;
  }
  const lensTableViews: LensTableViews = {
    roles: LensTableView,
    departments: LensTableView,
    titles: LensTableView,
    teams: LensTableTeams,
    systems: LensTableView,
  };

  const DynamicLensTableView = showLensFilters.active ? lensTableViews[showLensFilters.active] : null;

  const lensToFilter: keyof FilteredByT | null = showLensFilters.active;
  const lensSelected = lensToFilter ? filteredBy[lensToFilter] : null;

  return (
    <div className="admin-users">
      {usersStore.newlyImported.length > 0 && <NewUsersConfirmationModal users={usersStore.newlyImported} />}
      <div className="tr-flex flex-column align-content-start justify-content-start">
        <div className="tr-flex tr-mb-8 tr-items-center tr-gap-4">
          <AdvancedFilters
            filteredUsers={filteredUsers}
            filters={filters}
            setFilters={setFilters}
            setQuery={setQuery}
          />
          <AuthoritySelector />
        </div>
        <Tab.Group
          selectedIndex={selectedIndex}
          onChange={(i: number) => {
            setFilters({});
            setQuery('');
            setSelectedIndex(i);
          }}
        >
          <Tab.List variant="line" className="tr-mb-8">
            {_.map(activeTabs, (tab: string) => {
              return (
                <Tab data-testid={`tab-${tab}`} key={tab} to={`/admin/users/${tab}`} as={Link}>
                  <span className="tr-pr-3">
                    {_.upperFirst(tab === UserStatus.PENDING_APPROVAL ? 'Inactive' : tab)}
                  </span>
                  {_.size(presentStatuses[tab])}
                </Tab>
              );
            })}
          </Tab.List>
        </Tab.Group>
        <div>
          <LensFilters
            filteredUsers={filteredUsers}
            filters={filters}
            setFilters={setFilters}
            showLensFilters={showLensFilters}
            setShowLensFilters={setShowLensFilters}
            tab={tabs[selectedIndex]}
          />
          <UsersTypeFilters filteredUsers={filteredUsers} filters={filters} setFilters={setFilters} />
          <>
            {DynamicLensTableView ? (
              <DynamicLensTableView usersBy={lensSelected} teams={teams} />
            ) : (
              <>
                {presentStatuses[UserStatus.PENDING_APPROVAL]?.length &&
                  tabs[selectedIndex] === UserStatus.PENDING_APPROVAL && (
                    <div className="tr-flex tr-justify-end">
                      <OffboardInactiveUsersButton />
                    </div>
                  )}

                <UsersTable users={searchedAndFilteredRecords} tableKey={activeTabs[selectedIndex]} />

                {tabs[selectedIndex] === UserStatus.ACTIVE && _.size(filteredUsers) === 0 && (
                  <div className="tr-flex tr-flex-col align-items-center tr-mt-16">
                    <h2 className="tr-mt-0 tr-text-xl text-center font-weight-bold tr-text-navy">
                      {'Add Trustle Users'}
                    </h2>
                    <p className="tr-mt-0 tr-text-sm tr-text-navy">
                      You can add new Trustle users by importing them via CSV or IDP, or manually creating them.
                    </p>
                    <div className="tr-flex tr-flex-row align-items-center">
                      <AddUserButton />
                      <UsersImport />
                    </div>
                    <img className="tr-w-72 tr-mt-4" src="/russel-curious.svg" alt="" />
                  </div>
                )}
                {tabs[selectedIndex] === UserStatus.PENDING_APPROVAL && _.size(filteredUsers) === 0 && (
                  <div className="tr-flex tr-flex-col align-items-center tr-mt-16">
                    <img className="tr-w-72" src="/russel-researching.svg" alt="" />
                    <h2 className="tr-mt-0 tr-text-xl text-center font-weight-bold tr-text-navy">
                      {'Ready to bring more users into Trustle?'}
                    </h2>
                    <p className="tr-mt-0 tr-text-sm tr-text-navy">
                      You can add new Trustle users by importing them via CSV or IDP, or manually creating them.
                    </p>
                    <div className="tr-flex tr-flex-row align-items-center">
                      <AddUserButton />
                      <UsersImport />
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        </div>

        {loading && (
          <div className="tr-flex tr-justify-center">
            <Loading size={'sm'} dataLoadStatus={{isError: usersStore.error}} />
          </div>
        )}
      </div>
    </div>
  );
});

export default AdminUsersStatuses;
