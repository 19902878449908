import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {ColumnDescription} from 'react-bootstrap-table-next';
import {Button, Checkbox, Icon, Loading, Modal, Tab, Table, Tooltip} from '@trustle/component-library';

import {useRootStore} from '../../../../../lib/hooks';
import {UserType} from '../../../../../types';
import {UserStatus} from '../../../../../stores/usersStore';
import {IconTooltip} from '../../../../../components/IconTooltip';
import StatusIndicator, {translateStatus} from '../../../../../components/StatusIndicator';
import {renderRowIconForMandatoryField} from '../../authority-settings/helpers';
import {
  DepartmentCol,
  TitleCol,
  UserTypeCol,
} from '../../../../../components/design/tables/SharedColumnDefinitions/AccountColumns';
import {RoleCol} from '../../../../../components/design/tables/SharedColumnDefinitions/UserColumns';
import {fuseSearch} from '../../../../../lib';
import {Search} from '../../../../../components/Search/Search';
import {SyncAction} from '../../../../../views/Resource/Account/ResourceAccounts';
import {TableSetupEnum} from '../../../../../stores/userStore';
import {Resource} from '../../../../../stores/domainObjects/Resource';

// TODO: refactor this component

type Props = {
  resource: Resource;
  setShowModal: (show: boolean) => void;
};

export const SyncUsersModal = observer(({resource, setShowModal}: Props) => {
  const [syncInProgress, setSyncInProgress] = useState<boolean>(false);
  const {org, usersStore, currentUser} = useRootStore();

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selected, setSelected] = useState<any>([]);
  const [sortByEmail, setSortByEmail] = useState<'asc' | 'desc'>('asc');
  const [autolink, setAutolink] = useState<boolean>(true);
  const [keepDialogOpen, setKeepDialogOpen] = useState<boolean>(false);
  const [nonSelectable, setNonSelectable] = useState<number[]>([]);
  const [query, setQuery] = useState('');
  const [forceSyncInProgress, setForceSyncInProgress] = useState<boolean>(false);
  const [candidates, setCandidates] = useState<{creation: any[]; updates: any[]; switches: any[]}>({
    creation: [],
    updates: [],
    switches: [],
  });

  useEffect(() => {
    setCandidates(Object.assign(org.idpLastImport?.candidates || {creation: [], updates: [], switches: []}));
  }, [org.idpLastImport]);

  const isOrgOwner = (u: any) => u.email === currentUser.email && currentUser.isOrgOwner;

  const calculateStatus = (tombstone: boolean, allowLogin: boolean, type: UserType) => {
    return tombstone
      ? UserStatus.OFFBOARDED
      : !allowLogin && !_.includes([UserType.customer, UserType.system], type)
      ? UserStatus.DISABLED
      : UserStatus.ACTIVE;
  };

  const onConfirm = async (selected: any[], autolink = false) => {
    setSyncInProgress(true);

    const emailsList = _.map(selected, (s) => s.email);
    await org.confirmUserSelection(emailsList, autolink, resource?.id);

    if (!keepDialogOpen) {
      setShowModal(false);
    }
    await org.triggerUsersSync();
    setSelected([]);
    setSyncInProgress(false);
  };

  const {creation = [], updates = [], switches = []} = candidates;
  const allCandidates = [...creation, ...updates, ...switches];
  const invalid = _.filter(allCandidates, (u) => {
    return !u.valid;
  });

  const candidatesEmails = _.map(creation, 'email');

  const fieldMapping = org.idpSettings?.fieldMapping || {};

  const ManagerTypeCol: ColumnDescription = {
    dataField: 'manager',
    sort: true,
    text: 'Manager',
    formatter: (_field: string, record: any) => {
      const {managerEmail: email, manager: managerName} = record;
      const account = email?.split('@')[0];
      return (
        <div className="tr-my-1">
          <Tooltip content={<div className="tr-flex tr-p-1">{email}</div>} position="left" size="md">
            {managerName && <div className="font-weight-bold tr-mb-1`">{managerName}</div>}
            {account && <div>{account}</div>}
          </Tooltip>
        </div>
      );
    },
  };

  const columns: any = [
    {
      dataField: 'manager',
      sort: false,
      text: '',
      headerStyle: {width: '50px'},
      style: {width: '50px'},
      formatter: (field: string, record: any) => {
        if (!_.isEmpty(field)) {
          if (field === record.email) {
            return (
              <IconTooltip
                description="A user cannot be their own manager. If selected, the manager column will be ignored for this user."
                className="text-warning"
                type="warning"
              />
            );
          }

          const inOptions = _.includes(candidatesEmails, field);
          const exists = _.some(usersStore.users, (u) => {
            return u.email === field;
          });

          if (!exists && !inOptions) {
            setNonSelectable([...nonSelectable, record.email]);

            return (
              <IconTooltip
                description="The manager doesn't exist in Trustle. This row won't be selectable until the manager is created."
                className="text-danger"
                type="warning"
              />
            );
          }

          if (!exists && inOptions) {
            return (
              <IconTooltip
                description="If this row is selected, the corresponding manager needs to be created as well to maintain consistency.
                Otherwise, the manager will be updated on the next sync."
                className="text-warning"
                type="warning"
              />
            );
          }
        }

        if (isOrgOwner(record)) {
          return (
            <IconTooltip
              description="This is the current user and an Organization Owner in Trustle."
              className="tr-text-[#F08C00]"
              type="linked"
            />
          );
        }

        return <></>;
      },
    },
    {
      dataField: 'status',
      sort: false,
      text: '',
      headerStyle: {width: '50px'},
      style: {width: '50px'},
      formatter: (_field: string, record: any) => {
        const status = calculateStatus(record.tombstone, record.allowLogin, record.type);
        return <StatusIndicator status={translateStatus(status)} messages={[_.capitalize(status)]} />;
      },
    },
    {
      dataField: 'email',
      sort: true,
      text: 'User/Account',
      formatter: (_field: string, record: any) => {
        const {firstName, lastName, email} = record;
        const userAccount = email.split('@')[0];
        return (
          <div className="tr-my-1">
            <Tooltip content={<div className="tr-flex tr-p-1">{email}</div>} position="left" size="md">
              <div className="font-weight-bold tr-mb-1`">{`${firstName} ${lastName}`}</div>
              <div>{userAccount}</div>
            </Tooltip>
          </div>
        );
      },
      sortFunc: (_a: any, _b: any, order: 'asc' | 'desc', _dataField: string, _rowA: any, _rowB: any) => {
        setSortByEmail(order);
      },
    },
    renderRowIconForMandatoryField(ManagerTypeCol, fieldMapping),
    renderRowIconForMandatoryField(UserTypeCol, fieldMapping),
    renderRowIconForMandatoryField(DepartmentCol, fieldMapping),
    renderRowIconForMandatoryField(RoleCol, fieldMapping),
    renderRowIconForMandatoryField(TitleCol, fieldMapping),
    {
      dataField: 'diff',
      sort: false,
      hidden: selectedIndex !== 1,
      text: '',
      formatter: (field: string, _record: any) => {
        const attr = _.uniq(
          _.compact(
            _.map(field, (name: string) => {
              let formattedName;

              switch (name) {
                case 'tombstone':
                case 'allowLogin':
                  formattedName = 'Status';
                  break;
                case 'managerEmail':
                  formattedName = 'Manager';
                  break;
                default:
                  formattedName = _.startCase(name);
              }

              return formattedName;
            })
          )
        );

        if (_.isEmpty(attr)) {
          return <div></div>;
        }

        return (
          <Tooltip
            position="left"
            content={
              <div>
                <b>{'Updated: '}</b>
                {_.map(attr, (a) => (
                  <div>{a}</div>
                ))}
              </div>
            }
            className="tr-font-normal"
          >
            <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
          </Tooltip>
        );
      },
    },
  ];

  const getDescription = () => {
    return (
      <div className="tr-flex tr-flex-col tr-gap-3 tr-p-4">
        <h2 className="tr-mb-0">What are these tabs?</h2>
        <h3 className="tr-mb-0">Tab descriptions</h3>
        <div className="tr-mb-2">
          <strong>{_.startCase(SyncAction.NEW)}</strong>
          <span>{': These are new Trustle Users, managed by the remote Authority.'}</span>
        </div>

        <div>
          <strong>{_.startCase(SyncAction.UPDATES)}</strong>
          <span>
            {
              ': These are existing Trustle Users, managed by the remote Authority, which have Authority-sourced updates to apply.'
            }
          </span>
        </div>

        <div>
          <strong>{_.startCase(SyncAction.DUPLICATES)}</strong>
          <span>
            {
              ': These are existing Trustle Users, managed by the local (builtin) Trustle Authority, which are also found to exist on the remote Authority.  By selecting them, Authority for these Trustle Users will be transferred to the remote.'
            }
          </span>
        </div>

        <div>
          <strong>{_.startCase(SyncAction.INVALID)}</strong>
          <span>
            {
              ': Users that are missing data, or have corrupt data that impacts that user’s ability to login and use Trustle. These users will need to have these details correcte on the remote system to be successfully Synchronized.'
            }
          </span>
        </div>
      </div>
    );
  };

  function toggleSelection(rows: any[], isSelect: boolean) {
    setSelected(isSelect ? _.union(rows, selected) : _.differenceBy(selected, rows, 'email'));
  }

  const filter = (data: any[]) => {
    return fuseSearch(
      query,
      ['email', 'firstName', 'lastName', 'fullName', 'department', 'role', 'title'],
      _.map(data, (u: any) => {
        return {...u, fullName: _.join([u.firstName, u.lastName], ' ')};
      })
    );
  };

  const candidatesCount = _.size(allCandidates);
  const tabData =
    selectedIndex === 0 ? creation : selectedIndex === 1 ? updates : selectedIndex === 2 ? switches : invalid;
  const rowsToDisplay =
    selectedIndex === 3
      ? tabData
      : _.filter(tabData, (u) => {
          return u.valid;
        });
  const filteredData = filter(rowsToDisplay);
  const sortedData = _.orderBy(filteredData, ['ignored', 'email'], ['asc', sortByEmail]);

  const selectRow = {
    hideSelectAll: _.isEmpty(filteredData),
    mode: 'checkbox' as const,
    style: {background: 'rgba(0,81,155,0.1)', 'text-align': 'left'},
    headerColumnStyle: {background: 'rgba(0,0,0,0)', 'text-align': 'left'},
    onSelect: (row: any, isSelect: boolean) => toggleSelection([row], isSelect),
    onSelectAll: (isSelect: boolean, rows: any[]) => toggleSelection(rows, isSelect),
    nonSelectable,
  };

  const tabCount = (tab: SyncAction) => {
    const tabData =
      tab === SyncAction.NEW
        ? creation
        : tab === SyncAction.UPDATES
        ? updates
        : tab === SyncAction.DUPLICATES
        ? switches
        : invalid;

    const data =
      tab === SyncAction.INVALID
        ? tabData
        : _.filter(tabData, (u) => {
            return u.valid;
          });

    return _.isEmpty(query) ? _.size(data) : `${_.size(filter(data))} of ${_.size(data)}`;
  };

  return (
    <Modal visible={true} width="xl" onClose={() => setShowModal(false)} title={'Sync Users'}>
      <div>
        <div>
          <div className="tr-flex justify-between">
            <div className="tr-ml-1 tr-mt-3 body6">
              <strong>{`${candidatesCount} user candidates `}</strong>
              <span>{'to be synced'}</span>
              {!_.isEmpty(selected) && (
                <span className="tr-mt-3 body6">
                  <span className="tr-mx-3">{' >> '}</span>
                  <strong>{_.size(selected)}</strong>
                  <span>{' selected'}</span>
                </span>
              )}
            </div>
            <Button
              variant="secondary"
              disabled={forceSyncInProgress}
              onClick={async () => {
                setForceSyncInProgress(true);
                await org.triggerUsersSync();
                setForceSyncInProgress(false);
              }}
            >
              {forceSyncInProgress && <Loading size="xs">Retrieving Users...</Loading>}
              {!forceSyncInProgress && <div>{'Sync Now'}</div>}
            </Button>
          </div>
        </div>

        <div className="tr-mt-4">
          <Search
            placeholder="Search by Email, Name, Department, Role, or Title"
            filterOptions={{}}
            onChange={(query) => setQuery(query)}
          />
        </div>

        <div className="tr-mt-4">
          <Tab.Group selectedIndex={selectedIndex} onChange={(i: number) => setSelectedIndex(i)}>
            <Tab.List className="border-bottom">
              <Tab key={SyncAction.NEW}>{`${_.upperFirst(SyncAction.NEW)} (${tabCount(SyncAction.NEW)})`}</Tab>
              <Tab key={SyncAction.UPDATES}>{`${_.upperFirst(SyncAction.UPDATES)} (${tabCount(
                SyncAction.UPDATES
              )})`}</Tab>
              <Tab key={SyncAction.DUPLICATES}>{`${_.upperFirst(SyncAction.DUPLICATES)} (${tabCount(
                SyncAction.DUPLICATES
              )})`}</Tab>
              <Tab key={SyncAction.INVALID}>{`${_.upperFirst(SyncAction.INVALID)} (${tabCount(
                SyncAction.INVALID
              )})`}</Tab>
              <div className="tr-flex tr-ml-auto tr-p-2 tr-items-center">
                <Tooltip position="bottom" size="xl" content={getDescription()} className="tr-font-normal tr-mx-5">
                  <Button variant="ternary">What are these tabs?</Button>
                </Tooltip>
              </div>
            </Tab.List>
            <Tab.Panels>
              <div className="tr-max-h-[600px] tr-overflow-x-hidden tr-overflow-y-auto blue-scrollbar">
                <Table
                  tableKey={TableSetupEnum.USERS_SYNC}
                  overrideKeyField={'foreignId'}
                  columns={columns}
                  data={sortedData}
                  selectRow={selectRow}
                  striped={false}
                  wrapperClasses="rounded-xl border border-black rounded"
                  bordered={false}
                  rowClasses={(row: any, _rowIndex: number) =>
                    _.includes(selected, row)
                      ? 'tr-bg-blue-100'
                      : isOrgOwner(row)
                      ? 'authz-row'
                      : row.ignored
                      ? 'tr-bg-gray-100'
                      : ''
                  }
                  emptyMessage={
                    <div>
                      {selectedIndex === 0 ? 'No new users' : selectedIndex === 1 ? 'No updates' : 'No duplicates'}
                    </div>
                  }
                />
              </div>
            </Tab.Panels>
          </Tab.Group>
          <div>
            <div className="tr-flex tr-flex-row-reverse tr-mt-3 tr-mb-5 tr-pr-8 tr-mr-8">
              <Tooltip
                size="md"
                position="right"
                content={<div>{"Link selected users to unlinked accounts when there's a perfect email match"}</div>}
                className="tr-font-normal"
              >
                <div className="tr-flex align-items-center">
                  <Checkbox
                    checked={autolink}
                    onChange={() => setAutolink(!autolink)}
                    className="tr-flex tr-flex-none tr-flex-row tr-items-center"
                  />
                  <div className="cursor tr-text-xs strong tr-mx-3" onClick={() => setAutolink(!autolink)}>
                    Autolink selection
                  </div>
                </div>
              </Tooltip>
              <div className="tr-flex align-items-center">
                <Checkbox
                  checked={keepDialogOpen}
                  onChange={() => setKeepDialogOpen(!keepDialogOpen)}
                  className="tr-flex tr-flex-none tr-flex-row tr-items-center"
                />
                <div className="cursor tr-text-xs strong tr-mx-3" onClick={() => setAutolink(!keepDialogOpen)}>
                  Keep dialog open after confirmation
                </div>
              </div>
            </div>
            <div className="tr-flex tr-flex-row-reverse">
              <Button disabled={_.isEmpty(selected)} onClick={() => onConfirm(selected, autolink)}>
                {syncInProgress && <Loading size="xs">Processing...</Loading>}
                {!syncInProgress && <div>{`Sync Users (${_.size(selected)})`}</div>}
              </Button>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});
