import _, {Dictionary} from 'lodash';
import {LicenseUsage} from './types';

export type LicenseUsageWithLicense = LicenseUsage & {license: string};

function splitArrayIntoBuckets(array: any[], numberOfBuckets: number) {
  const totalElements = array.length;
  const bucketSize = Math.floor(totalElements / numberOfBuckets);
  const extraElements = totalElements % numberOfBuckets;

  const buckets = [];
  let startIndex = 0;

  for (let i = 0; i < numberOfBuckets; i++) {
    const currentBucketSize = bucketSize + (i < extraElements ? 1 : 0);
    const endIndex = startIndex + currentBucketSize;
    buckets.push(array.slice(startIndex, endIndex));
    startIndex = endIndex;
  }

  return buckets;
}

function aggregateLicenseUsage(bucket: LicenseUsageWithLicense[], reportingPeriod: string) {
  return _.chain(bucket)
    .groupBy('license')
    .map((items, license) => {
      const aggregated = items.reduce((acc, curr) => {
        acc.sum_of_granted_hours += curr.sum_of_granted_hours;
        acc.sum_of_usage_hours += curr.sum_of_usage_hours;
        return acc;
      });
      aggregated.reporting_period = reportingPeriod;
      aggregated.license = license;
      return aggregated;
    })
    .value();
}

export function groupUsageByPeriod(data: Dictionary<LicenseUsageWithLicense[]>, numberOfBuckets: number) {
  const sortedDates = Object.keys(data).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  const buckets = splitArrayIntoBuckets(sortedDates, numberOfBuckets).filter((bucket) => bucket.length > 0);

  return buckets.reduce((result: Dictionary<LicenseUsageWithLicense[]>, dates) => {
    const startPeriod = dates[0];
    const endPeriod = dates[dates.length - 1];
    const periodKey = startPeriod === endPeriod ? startPeriod : `${startPeriod}|${endPeriod}`;
    const aggregatedData = dates.reduce((acc: LicenseUsageWithLicense[], date: string) => {
      return acc.concat(data[date]);
    }, []);
    result[periodKey] = aggregateLicenseUsage(aggregatedData, periodKey);
    return result;
  }, {});
}

export function formatTimestamp(timestamp: string) {
  const date = new Date(timestamp);

  // Extract date components
  const month = date.getMonth() >= 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`; // Months are zero-indexed, so add 1
  const day = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;
  const year = date.getFullYear();

  // Format hours and minutes
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  // Determine AM or PM
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // Convert hour '0' to '12'

  // Construct the formatted string
  return `${month}/${day}/${year} @${hours}:${minutes}${ampm}`;
}
