import _ from 'lodash';
import axios from 'axios';
import {useCallback, useContext, useEffect, useReducer, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import * as logger from './logger';
import {StoreContext} from 'src/storeContext';
import useMediaQuery from './mediaQuery';
import {encryptData} from './crypto';

export type DataLoaderControlT = {
  refresh: () => void;
  isError: boolean;
  isReady: boolean;
  error: any;
};

function useDataLoader<T = any>(url: string): [T | undefined, DataLoaderControlT] {
  const [loadState, setLoadState] = useState(0);
  const [data, setData] = useState();
  const [error, setError] = useState();

  // Each server side call has 4 states:
  //   0: no state.
  //   1: Server network call is being started. Assume it's already happened and we're waiting for a reply.
  //   2: Server network call returnned successfully, and the data is ready to process.
  //   3: Server network call failed. The error is available on the console.

  useEffect(() => {
    const load = async (_param?: string) => {
      try {
        setLoadState(1);
        const result = await axios.get(url);

        setData(result.data);
        setLoadState(2);
      } catch (err: any) {
        logger.trace(err);
        setError(err);
        setLoadState(-1);
      }
    };
    if (loadState === 0) {
      void load();
    }
  });

  const refresh = () => {
    setLoadState(0);
  };

  return [data, {refresh, isError: loadState === -1, isReady: loadState === 2, error}];
}

class FeatureFlagView {
  data: string[];

  constructor(data: string[]) {
    this.data = data;
  }

  isEnabled(flagName: string): boolean {
    return _.includes(this.data, flagName);
  }
}

function useFeatureFlags(): FeatureFlagView {
  return new FeatureFlagView(useRootStore()?.org?.featureFlags ?? []);
}

function useDebounce(actionFn: (value: any) => void, debouncePeriodInMs: number) {
  // Example code from `https://www.freecodecamp.org/news/debounce-and-throttle-in-react-with-hooks/`.
  const debounceRunner = useCallback(_.debounce(actionFn, debouncePeriodInMs), []);

  return (val: any) => {
    debounceRunner(val);
  };
}

function useQueryParams(paramNames: string[]): Array<string | null> {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  return _.map(paramNames, (paramName: string) => {
    const value = query.get(paramName);
    if (_.isNil(value)) {
      return null;
    }
    return value;
  });
}

/**
 * Define state to be tracked in URL search params.
 *
 * @param queryParams optionally define initial values for url search params
 * @returns an object representing URL search params, a function for updating them, and a function to clear them
 */
function useQueryStrings(
  queryParams?: Record<string, string | string[] | undefined>
): [Record<string, string>, (newValues: Record<string, string | string[] | undefined>) => void, () => void] {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const normalizedQueryParams = _.mapValues(queryParams, (val) => (_.isArray(val) ? val.join(',') : val));
  const [values, setValues] = useState<Record<string, string>>({
    ...(_.omitBy(normalizedQueryParams, _.isEmpty) as Record<string, string>),
    ...Object.fromEntries(params.entries()),
  });

  const history = useHistory();

  useEffect(() => {
    // skip any history updates when params already match values
    if (_.isEqual(Object.fromEntries(params.entries()), values)) {
      return;
    }

    // search all keys from url and from new values
    [...Array.from(params.keys()), ...Object.keys(values)]
      // find any where the url param doesn't match latest values
      .filter((key) => params.get(key) !== values[key])
      // make the url param match the latest value
      .forEach((key) => (values[key] !== undefined ? params.set(key, values[key]) : params.delete(key)));

    const search = params.toString().length === 0 ? '' : `?${params.toString()}`;
    history.push({...history.location, search});
  }, [values]);

  // something mutated the url search params, set values to what is in url
  useEffect(() => setValues(Object.fromEntries(params.entries())), [useLocation().search]);

  return [
    values,
    (newValues) => {
      const normalizedNewValues = _.mapValues(newValues, (val) => (_.isArray(val) ? val.join(',') : val));
      setValues(_.omitBy(normalizedNewValues, _.isEmpty) as Record<string, string>);
    },
    () => setValues({}),
  ];
}

function publishSessionEvent(): void {
  // See useDataLoader() above regarding state. This publish event is fire and
  // forget, hence only 2 states are used here:
  //   0: No state.
  //   1: Server network call is being started, don't care about the result.
  const [sessionEventFired, setSessionEventFired] = useState(0);

  useEffect(() => {
    const sendEvent = async () => {
      try {
        setSessionEventFired(1);
        await axios.get('/api/analytics/session');
      } catch (err) {
        logger.trace(err);
      }
    };

    if (sessionEventFired === 0) {
      void sendEvent();
    }
  });
}

type DataFilterReducerAction = {
  type: 'toggle' | 'set';
  key: string;
  value: any;
};

function DataFilterReducer(state: _.Dictionary<any>, action: DataFilterReducerAction) {
  const {key, value} = action;
  switch (action.type) {
    case 'toggle': {
      // Assumes that state[key] is an array.
      //   This action adds/removes the provided value from the array.
      const newKeyState = _.cloneDeep(state[key]);
      if (newKeyState.indexOf(value) > -1) {
        newKeyState.splice(newKeyState.indexOf(value), 1);
      } else {
        newKeyState.push(value);
      }
      return {
        ...state,
        [key]: newKeyState,
      };
    }

    case 'set': {
      return {
        ...state,
        [key]: value,
      };
    }
  }
}

function useDataFilter(initialFilter: any) {
  return useReducer(DataFilterReducer, initialFilter);
}

function useRootStore() {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }

  return context;
}

function useConnectionCredentials() {
  const featureFlagViewer = useFeatureFlags();
  const {org} = useRootStore();
  const encrypt = featureFlagViewer.isEnabled('connection_credentials_encryption');
  const PUBLIC_ENCRYPTION_KEY = org.publishableKeys.authPubKey;
  const credentials = async (connectionId: string | null | undefined, payload: any) => {
    let finalPayload = payload;
    if (encrypt) {
      finalPayload = {
        encryptedPayload: encryptData(payload, PUBLIC_ENCRYPTION_KEY),
      };
    }
    return axios.post(`/api/connect/${connectionId}/credentials`, {...finalPayload});
  };
  const testConnection = async (connectionId: string | null | undefined, payload: any) => {
    let finalPayload = payload;
    if (encrypt) {
      finalPayload = {
        encryptedPayload: encryptData(payload, PUBLIC_ENCRYPTION_KEY),
      };
    }
    return axios.post(`/api/connect/${connectionId}/setup/test_connection`, {...finalPayload});
  };
  return {credentials, testConnection};
}

export {
  useDataLoader,
  useFeatureFlags,
  useDebounce,
  useQueryParams,
  useQueryStrings,
  publishSessionEvent,
  useDataFilter,
  useRootStore,
  useMediaQuery,
  useConnectionCredentials,
};
