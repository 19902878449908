import React, {useState} from 'react';
import ReportLine from './reports/ReportLine';
import ReportDropdown from './reports/ReportDropdown';

import CustomReport from './reports/customReport/CustomReport';

const CurrentAccessReport = () => (
  <ReportLine
    title="Current Access for Entire Organization"
    description="Create a report that shows access across all systems managed by Trustle."
    actionElement={<ReportDropdown title="Download Report" url={`/api/orgs/compliance_report/`} />}
  />
);

const CustomAccessReport = ({onClick}: Pick<React.HTMLProps<HTMLDivElement>, 'onClick'>) => (
  <ReportLine
    title="Custom Access Report"
    description="Select Specific systems to generate report for specific time frame."
    actionElement={
      <div className="btn btn-primary" role="button" onClick={onClick}>
        Generate Report &gt;
      </div>
    }
  />
);

function AdminCompliance() {
  const [showCustomReport, setShowCustomReport] = useState(false);

  return (
    <>
      <div className="my-3 tr-mb-20">
        {!showCustomReport && <CurrentAccessReport />}
        {!showCustomReport && <CustomAccessReport onClick={() => setShowCustomReport(true)} />}
        {showCustomReport && <CustomReport />}
      </div>
    </>
  );
}

export default AdminCompliance;
