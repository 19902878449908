import _ from 'lodash';
import React, {useState} from 'react';
import {SubmitButton} from 'src/components/design';
import {Field, Formik, Form as FormikForm} from 'formik';
import {logger} from 'src/lib';
import {convertResourceToForm} from 'src/lib/ancestors';
import {object} from 'yup';
import {observer} from 'mobx-react';
import {Resource} from 'src/stores/domainObjects/Resource';
import {
  Button,
  DurationInput,
  SensitivityDropdown,
  VisibilityDropdown,
  Icon,
  Tooltip,
  ProvisionModeDropdown,
  Confirmation,
} from '@trustle/component-library';
import {useRootStore} from 'src/lib/hooks';
import {GetSensitivityDurationValidations} from 'src/lib/validations';
import AutomatedProvisionToggle from 'src/connectors/components/AutomatedProvisionToggle';
import AutomatedProvisioningModeTooltip from 'src/connectors/common/editConnection/AutomatedProvisioningModeTooltip';
import {ConnectionServiceE, ProvisionOptions, SensitivitySettingT} from 'src/types';
import {EditNameDescription} from '../Permissions/PermissionEdit';
import DefaultOrOverriden from '../Permissions/DefaultOrOverriden';
import {Loading} from '@trustle/component-library';
import * as Yup from 'yup';
import {Permission} from 'src/stores/domainObjects/Permission';

type PropsT = {
  resource: Resource;
  cancel: () => void;
};

/**
 * TODO: this component is very similar to PermissionEdit component.
 * In a near future we should create a component for the fields and replace it in order to apply DRY pattern
 */
export type ResourceAttributeT =
  | 'hidden'
  | 'autoProvision'
  | 'durationUnit'
  | 'durationValue'
  | 'accessDurationUnit'
  | 'sensitivityId'
  | 'accessDurationValue'
  | 'provisionMode'
  | 'deprovisionMode'
  | 'initiateExpiredDeprovision';

const ResourceEdit = observer(function ResourceEditCmp(props: PropsT) {
  const {resource, cancel} = props;
  const resourceType = resource.type;
  const [showConfirmDefaultChildrens, setConfirmDefaultChildrens] = useState<boolean>(false);
  const {org} = useRootStore();
  const [cleared, setAsCleared] = useState<boolean>(false);
  const [resetAll, setResetAll] = useState<boolean>(false); // told if reset all was clicked
  const [touchedFields, setTouchedFields] = useState<string[]>([]); // told which fields are touched

  if (_.isNil(org) || org?.defaultSensitivitySetting === undefined) {
    return <Loading />;
  }

  const initialValues = convertResourceToForm(org, resource);
  //Second part of clause is inherited from SystemSetupForm
  const isConnectedSystem = !!resource.rootResource.connectionId;

  async function handleSave(form: any) {
    if (!resource) {
      return {error: true, msg: 'Resource does not exist.'};
    }
    const resourceUpdate: Record<string, any> = {
      label: form.label,
      description: form.description,
    };

    for (const fieldName of [
      'provisionMode',
      'deprovisionMode',
      'initiateExpiredDeprovision',
      'autoProvision',
      'hidden',
    ]) {
      if (!_.includes(touchedFields, fieldName) && resetAll) {
        resourceUpdate[fieldName] = null;
      } else if (_.includes(touchedFields, fieldName)) {
        resourceUpdate[fieldName] = (form as any)[fieldName];
      }
    }

    // Fields with values calculated based on the hierarchy (sensitivity and durations):
    // if the new value for the field is the same as the value in its parent, it means it is being inherited,
    // then the backend will store this as 'null'
    const sensitivityInherited = resource.parentSensitivityId === form.sensitivityId;

    resourceUpdate['sensitivityId'] = sensitivityInherited ? null : form.sensitivityId;

    const {approvalDuration, accessDuration} = calculateNewDurations(sensitivitySettings, form.sensitivityId, resource);

    const approvalInherited =
      approvalDuration.durationValue === form.approvalDuration?.durationValue &&
      approvalDuration.durationUnit === form.approvalDuration?.durationUnit;

    resourceUpdate['durationValue'] = approvalInherited ? null : form.approvalDuration?.durationValue;
    resourceUpdate['durationUnit'] = approvalInherited ? null : form.approvalDuration?.durationUnit;

    const accessInherited =
      accessDuration.durationValue === form.accessDuration?.durationValue &&
      accessDuration.durationUnit === form.accessDuration?.durationUnit;

    resourceUpdate['accessDurationValue'] = accessInherited ? null : form.accessDuration?.durationValue;
    resourceUpdate['accessDurationUnit'] = accessInherited ? null : form.accessDuration?.durationUnit;

    try {
      await resource.update(resourceUpdate);
      cancel?.();
      return {msg: 'Resource updated sucessfully'};
    } catch (err) {
      logger.error(err);
      return {error: true, msg: 'Error saving resource.'};
    }
  }

  function handleOnChange(event: any) {
    const fieldName = event.target?.name || event.field;
    if (!_.isNil(fieldName)) {
      setTouchedFields((ptf) => {
        ptf.push(fieldName);
        return ptf;
      });
      setAsCleared(false);
    }
  }

  const sensitivitySettings = org.sensitivitySettingsSorted;
  const sensitivityDurationValidations = GetSensitivityDurationValidations(org);
  const validationSchema = object()
    .shape(sensitivityDurationValidations)
    .shape({
      name: Yup.string().max(80, 'The name may not be greater than 80 characters'),
      description: Yup.string().optional().max(500, 'The description may not be greater than 500 characters'),
    });

  return (
    <Formik initialValues={initialValues} onSubmit={handleSave} validationSchema={validationSchema}>
      {({
        isSubmitting,
        values,
        setFieldValue,
        resetForm,
        isValid,
        touched,
        handleBlur,
        setFieldTouched,
        setTouched,
        setErrors,
      }) => {
        return (
          <FormikForm className="resource-edit text-left">
            <EditNameDescription isConnectedSystem={isConnectedSystem} values={values} />
            {!resource.isLocked && (
              <>
                <Field name="sensitivityId">
                  {({field}: any) => (
                    <div className="tr-border-solid tr-border-[0px] tr-border-b-[1px] tr-border-b-gray-300 tr-ml-2 tr-p-2 tr-flex tr-flex-row tr-items-center">
                      <div className="tr-basis-2/6 tr-flex">
                        <label className="tr-font-bold m-0">Sensitivity</label>
                        <Tooltip
                          content={
                            <div className="body6">
                              Select a sensitivity setting to control approval and provision requirements.
                            </div>
                          }
                        >
                          <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                        </Tooltip>
                      </div>
                      <div key="sensitivity" className="tr-basis-3/6 tr-justify-center tr-pl-5">
                        <SensitivityDropdown
                          size="sm"
                          data-testid={'sensitivity-defaultLevelDropdown'}
                          defaultValue={resource.parentResource?.calculatedSensitivity.value}
                          sensitivitySettings={sensitivitySettings!}
                          selectedId={field.value}
                          onStateSelected={async (sensitivityId) => {
                            setErrors({});
                            setFieldTouched('accessDuration', false, false);
                            setFieldTouched('approvalDuration', false, false);

                            setTouched({}, false);

                            const result = calculateNewDurations(sensitivitySettings, sensitivityId, resource);
                            values.approvalDuration = result.approvalDuration;
                            values.accessDuration = result.accessDuration;

                            setTouchedFields((ptf) => {
                              ptf.push('approvalDuration');
                              ptf.push('accessDuration');
                              ptf.push('sensitivityId');
                              return ptf;
                            });

                            field.onChange({target: {name: 'approvalDuration', value: values.approvalDuration}});
                            field.onChange({target: {name: 'accessDuration', value: values.accessDuration}});
                            field.onChange({target: {name: field.name, value: sensitivityId}});

                            setAsCleared(false);
                          }}
                          onBlur={handleBlur}
                          id="sensitivityId"
                        />
                      </div>
                      <div className="tr-basis-1/6">
                        {!isSubmitting && (
                          <>
                            <DefaultOrOverriden
                              value={values.sensitivityId}
                              touched={values.sensitivityId !== resource.parentSensitivityId}
                              forceDefault={values.sensitivityId === resource.parentSensitivityId}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Field>
                <Field name="hidden">
                  {({field}: any) => (
                    <div className="tr-border-solid tr-border-[0px] tr-border-b-[1px] tr-border-b-gray-300 tr-ml-2 tr-p-2  tr-flex tr-flex-row tr-items-center">
                      <div className="tr-basis-2/6 tr-flex">
                        <label className="tr-font-bold m-0">Visibility</label>
                        <Tooltip
                          content={
                            <div className="body6">
                              Select whether a permission should be visible or not for normal users
                            </div>
                          }
                        >
                          <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                        </Tooltip>
                      </div>
                      <div className="tr-basis-3/6 tr-justify-center tr-pl-5">
                        <VisibilityDropdown
                          size="sm"
                          selectedStatus={field.value}
                          data-testid={'toggleVisibilityIcon'}
                          onSelected={(hidden?: boolean) => {
                            field.onChange({target: {name: 'hidden', value: hidden}});
                            setTouchedFields((ptf) => {
                              ptf.push('hidden');
                              return ptf;
                            });
                            setAsCleared(false);
                          }}
                          id="hidden"
                          onBlur={handleBlur}
                        />
                      </div>
                      <div className="tr-basis-1/6">
                        {!isSubmitting && (
                          <DefaultOrOverriden
                            value={resource.hidden}
                            touched={!cleared && _.includes(touchedFields, 'hidden')}
                            forceDefault={cleared && !_.includes(touchedFields, 'hidden')}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </Field>
              </>
            )}
            {!resource.isLocked && resourceType !== ConnectionServiceE.GENERIC && (
              <div className="tr-border-solid tr-border-[0px] tr-border-b-[1px] tr-border-b-gray-300">
                <div className="tr-ml-2 tr-p-2 tr-items-center">
                  <div className="tr-mt-2">
                    <AutomatedProvisioningModeTooltip resource={props.resource.parentResource} />
                  </div>
                </div>
                <div className="">
                  <Field name="provisionMode">
                    {({field}: any) => (
                      <div className="tr-border-solid tr-border-[0px] tr-border-b-[1px] tr-border-b-gray-300 tr-ml-2 tr-p-2 tr-flex tr-flex-row tr-items-center">
                        <div className="tr-basis-2/6 tr-flex">
                          <span className="tr-ml-16">
                            <label className="tr-font-bold m-0">Provisioning</label>
                          </span>
                        </div>
                        <div className="tr-basis-3/6 tr-justify-center tr-pl-5">
                          <ProvisionModeDropdown
                            size="sm"
                            className="tr-w-[140px]"
                            selectedStatus={field.value}
                            data-testid={'provisioningMode'}
                            onBlur={handleBlur}
                            id="provisionMode"
                            onSelected={(value?: string) => {
                              field.onChange({target: {name: 'provisionMode', value: value}});
                              setTouchedFields((ptf) => {
                                ptf.push('provisionMode');
                                return ptf;
                              });
                              setAsCleared(false);
                            }}
                          ></ProvisionModeDropdown>
                        </div>
                        <div className="tr-basis-1/6">
                          {!isSubmitting && (
                            <DefaultOrOverriden
                              forceDefault={cleared && !_.includes(touchedFields, 'provisionMode')}
                              value={resource.provisionMode}
                              touched={!cleared && _.includes(touchedFields, 'provisionMode')}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </Field>

                  <Field name="deprovisionMode">
                    {({field}: any) => (
                      <div className="tr-border-solid tr-border-[0px] tr-border-b-[1px] tr-border-b-gray-300 tr-ml-2 tr-p-2  tr-flex tr-flex-row tr-items-center">
                        <div className="tr-basis-2/6 tr-flex">
                          <span className="tr-ml-16">
                            <label className="tr-font-bold m-0">Deprovisioning</label>
                          </span>
                        </div>
                        <div className="tr-basis-3/6 tr-justify-center tr-pl-5">
                          <ProvisionModeDropdown
                            size="sm"
                            className="tr-w-[140px]"
                            selectedStatus={field.value}
                            data-testid={'deprovisioningMode'}
                            onBlur={handleBlur}
                            id="deprovisionMode"
                            onSelected={(value?: string) => {
                              field.onChange({target: {name: 'deprovisionMode', value: value}});
                              if (value === ProvisionOptions.off) {
                                setFieldValue('initiateExpiredDeprovision', false, false);
                              }
                              setTouchedFields((ptf) => {
                                ptf.push('deprovisionMode');
                                return ptf;
                              });
                              setAsCleared(false);
                            }}
                          ></ProvisionModeDropdown>
                        </div>
                        <div className="tr-basis-1/6">
                          {!isSubmitting && (
                            <DefaultOrOverriden
                              forceDefault={cleared && !_.includes(touchedFields, 'deprovisionMode')}
                              value={resource.deprovisionMode}
                              touched={!cleared && _.includes(touchedFields, 'deprovisionMode')}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </Field>

                  <div className="tr-border-solid tr-border-[0px] tr-border-b-[1px] tr-border-b-gray-300 tr-ml-2 tr-p-2 tr-flex tr-flex-row tr-items-center tr-my-2">
                    <div className="tr-basis-5/6 tr-flex tr-items-center">
                      <span className="tr-ml-16">
                        <AutomatedProvisionToggle
                          type="checkbox"
                          disabled={_.isNil(values.deprovisionMode) || values.deprovisionMode === ProvisionOptions.off}
                          className="tr-mr-5 tr-justify-center"
                          name="initiateExpiredDeprovision"
                          onClick={() => {
                            setFieldTouched('initiateExpiredDeprovision', true);
                            setTouchedFields((ptf) => {
                              ptf.push('initiateExpiredDeprovision');
                              return ptf;
                            });
                            setAsCleared(false);
                          }}
                        />
                      </span>
                      <div className="tr-flex-initial tr-grow">
                        <div className="tr-flex-auto">
                          <span className="body4">Initiate Deprovisioning When Access Expires</span>
                          <Tooltip
                            content={
                              <div className="tr-cols-1 tr-grid tr-gap-y-4">
                                <div>
                                  When enabled, we will automatically initiate the deprovisioning process when either
                                  approval or provision access expires.
                                </div>
                                <div>
                                  In <strong>Manual mode</strong>, a task will be generated automatically for
                                  deprovisioning of the expired resource. This task is sent to the Provisioner, who must
                                  make the change on the remote system then click in the TaskUI <i>"It's Complete"</i>,
                                  or not make the change on the remote system and click "Cancel."
                                </div>
                              </div>
                            }
                          >
                            <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                          </Tooltip>
                        </div>
                      </div>
                    </div>

                    <div className="tr-basis-1/6">
                      {!isSubmitting && (
                        <DefaultOrOverriden
                          forceDefault={cleared && !_.includes(touchedFields, 'initiateExpiredDeprovision')}
                          value={resource.initiateExpiredDeprovision}
                          touched={!cleared && touched.initiateExpiredDeprovision}
                        />
                      )}
                    </div>
                  </div>


                </div>
              </div>
            )}
            {!resource.isLocked && (
              <div className="tr-ml-2 tr-pl-2 tr-mt-2 tr-pt-2 tr-items-center">
                <div className="body4">Duration Settings</div>
                <div className="tr-w-full tr-mb-4 tr-py-2 tr-items-start tr-grid tr-grid-cols-2 tr-gap-x-8">
                  <div className="tr-border-solid tr-flex tr-border-[0px] tr-border-r-[1px] tr-border-r-gray-300">
                    <Field
                      component={DurationInput}
                      label="Approval Duration"
                      className="tr-w-9/12"
                      description="This is the default amount of time a user will be approved for."
                      name="approvalDuration"
                      required={false}
                      onChange={handleOnChange}
                      onBlur={handleBlur}
                    />
                    {!isSubmitting && (
                      <div className="flex items-end tr-text-right tr-text-align-bottom tr-mb-2 tr-px-2">
                        <DefaultOrOverriden
                          value={
                            _.isNil(values.approvalDuration.durationValue) ||
                            _.isNil(values.approvalDuration.durationUnit)
                              ? null
                              : 1
                          }
                          touched={
                            values.approvalDuration.durationValue !== resource.calcApprovalDuration.durationValue ||
                            values.approvalDuration.durationUnit !== resource.calcApprovalDuration.durationUnit
                          }
                          forceDefault={(() => {
                            const {approvalDuration} = calculateNewDurations(
                              sensitivitySettings,
                              values.sensitivityId || undefined,
                              resource
                            );

                            return (
                              values.approvalDuration.durationValue === approvalDuration.durationValue &&
                              values.approvalDuration.durationUnit === approvalDuration.durationUnit
                            );
                          })()}
                        />
                      </div>
                    )}
                  </div>

                  <div className="tr-flex">
                    <Field
                      component={DurationInput}
                      name="accessDuration"
                      label="Access Duration"
                      onChange={handleOnChange}
                      onBlur={handleBlur}
                      className="tr-w-9/12"
                      description="This is the default amount of time a user will be provisioned for."
                      required={false}
                    />
                    {!isSubmitting && (
                      <div className="flex items-end tr-text-right tr-text-align-bottom tr-mb-2">
                        <DefaultOrOverriden
                          value={
                            _.isNil(values.accessDuration.durationValue) ||
                            _.isNil(values.accessDuration.durationUnit)
                              ? null
                              : 1
                          }
                          touched={
                            values.accessDuration.durationValue !== resource.calcAccessDuration.durationValue ||
                            values.accessDuration.durationUnit !== resource.calcAccessDuration.durationUnit
                          }
                          forceDefault={(() => {
                            const {accessDuration} = calculateNewDurations(
                              sensitivitySettings,
                              values.sensitivityId || undefined,
                              resource
                            );

                            return (
                              values.accessDuration.durationValue === accessDuration.durationValue &&
                              values.accessDuration.durationUnit === accessDuration.durationUnit
                            );
                          })()}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="tr-text-right tr-pt-4 tr-flex tr-justify-between">
              <div>
                <Button
                  variant="ternary"
                  title="Resetting to default changes this resource`s values to the value that is inherited from the ancestors or the configuration"
                  onClick={() => {
                    // reset values inheriting parent values in order to show in the form.
                    // At DB level corresponds to set to null.
                    resetForm();
                    setFieldValue('approvalDuration', resource.inheritedApprovalDuration);
                    setFieldValue('accessDuration', resource.inheritedAccessDuration);
                    setFieldValue('autoProvision', resource.parentResource?.autoProvisionValue.value);
                    setFieldValue('hidden', resource.parentResource?.hidden);
                    setFieldValue('sensitivityId', resource.parentResource?.sensitivity?.id);
                    setFieldValue('provisionMode', resource.parentResource?.calculatedProvisionMode.value);
                    setFieldValue('deprovisionMode', resource.parentResource?.calculatedDeprovisionMode.value);
                    setFieldValue(
                      'initiateExpiredDeprovision',
                      resource.parentResource?.calculatedInitiateExpiredDeprovision.value
                    );
                    setAsCleared(true);
                    setResetAll(true);
                    setTouchedFields([]);
                  }}
                >
                  Reset to default
                </Button>
                {!resource.isLocked && (
                  <Button
                    variant="ternary"
                    onClick={() => {
                      setConfirmDefaultChildrens(true);
                    }}
                  >
                    Reset descendents to default
                    <Tooltip
                      content={
                        <div>
                          By confirming, you'll reset all children of <strong>{resource.name}</strong> to their default
                          configurations. This cannot be undone.
                        </div>
                      }
                    >
                      <Icon type="moreInfo" size="sm" className="pb-1" />
                    </Tooltip>
                  </Button>
                )}
                {showConfirmDefaultChildrens && (
                  <Confirmation
                    size="md"
                    variant="primary"
                    title={`Reset all ${resource.name} descendents settings.`}
                    onClose={() => {
                      setConfirmDefaultChildrens(false);
                    }}
                    onConfirm={async () => {
                      await resource.resetChildsToDefault(resource.id);
                      setConfirmDefaultChildrens(false);
                    }}
                  >
                    <div>
                      By confirming, you'll reset all children of <strong>{resource.name}</strong> to their default
                      configurations. This cannot be undone.
                    </div>
                    <div>
                      <small>This action could take some time. </small>
                    </div>
                  </Confirmation>
                )}
              </div>
              <div>
                <Button
                  variant="secondary"
                  onClick={() => {
                    cancel?.();
                  }}
                >
                  Cancel
                </Button>
                <SubmitButton disabled={!isValid} inSubmit={isSubmitting} name="save" label="Save" />
              </div>
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );
});

// if the sensitivity is updated but not saved yet, we need to recalculate the corresponding durations to show in the UI and perform validations
export function calculateNewDurations(
  sensitivitySettings: SensitivitySettingT[],
  sensitivityId: string | undefined,
  resourceOrPerm: Resource | Permission
) {
  if (sensitivityId && sensitivityId !== resourceOrPerm.parentResource?.calculatedSensitivity.value) {
    // the user has selected a new sensitivity in the dropdown, different from the parent's sensitivity:
    // the duration fields need to change to display the max value based on the settings
    const sensitivity = _.find(sensitivitySettings, {id: sensitivityId})!;

    return {
      approvalDuration: {
        durationValue: sensitivity.maxApprovalDurationValue,
        durationUnit: sensitivity.maxApprovalDurationUnit,
      },
      accessDuration: {
        durationValue: sensitivity.maxAccessDurationValue,
        durationUnit: sensitivity.maxAccessDurationUnit,
      },
    };
  } else {
    // the sensitivity selected in the dropdown matches with the parent's sensitivity:
    // the duration fields need to change to display the values inherited from the parent (in case they're overriden in the parent as well)
    const parentApproval = resourceOrPerm.inheritedApprovalDuration;
    const parentAcess = resourceOrPerm.inheritedAccessDuration;

    return {
      approvalDuration: {durationValue: parentApproval.durationValue, durationUnit: parentApproval.durationUnit},
      accessDuration: {durationValue: parentAcess.durationValue, durationUnit: parentAcess.durationUnit},
    };
  }
}

export default ResourceEdit;
