import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import {makeAutoObservable, onBecomeObserved, runInAction} from 'mobx';
import {SensitivityLevelDefinitions} from 'src/components/sensitivity/SensitivityConfiguration';
import {
  DurationUnitT,
  ResourceHistoryItemT,
  MinSensitivitySettingT,
  NodeImportStateT,
  ConnectorsDataDataTypeE,
  ConnectorsDataT,
  ConnectionServiceE,
  ProvisionOptions,
  DurationT,
  AccessRecordT,
  statusWKey,
  AccessResponseT,
  AccessItemResponseT,
} from 'src/types';
import RootStore from '..';
import {AccessRecord} from './AccessRecord';
import {Resource} from './Resource';
import {PermissionResponseT} from '../PermissionStore';
import {getErrorMessage} from 'src/utils';
import {Icon} from '@trustle/component-library';

export class Permission {
  id!: string;
  oid!: string;
  refId!: string;
  refType!: string;
  label!: string;
  description!: string | null;
  hidden?: boolean | null;
  created?: string;
  approvalDefinition?: string;
  provisionInstructions?: string;
  reapprovalDefinition?: string;
  provisionDefinition?: string;
  deprovisionDefinition?: string;
  accessInstructions?: string;
  durationValue?: number;
  durationUnit?: DurationUnitT;
  accessDurationValue?: number = undefined;
  accessDurationUnit?: DurationUnitT = undefined;
  autoProvision?: boolean | null;
  numAccounts?: number; // present only on resources returned by /resources/:rid
  sensitivityId?: string | null = undefined;
  tombstone?: boolean | null;
  connectorData?: any[] = undefined;
  accessesConnectorData?: ConnectorsDataT[] = undefined;
  updated?: string;
  reviewedAt?: string;
  lastChange?: ResourceHistoryItemT;
  autoSave: boolean = true;
  serviceUsage: any = undefined;
  provisionMode?: ProvisionOptions | null = null;
  deprovisionMode?: ProvisionOptions | null = null;
  initiateExpiredDeprovision?: boolean | null = null;
  private locked?: boolean = false;
  nodeImportState?: NodeImportStateT;
  loadingAccesses: boolean = false;
  accessesLoadedAtLeastOnce: boolean = false;
  permAccessIds: any = undefined;

  private rootStore: RootStore;

  constructor(rootStore: RootStore, permission: Partial<PermissionResponseT>) {
    makeAutoObservable(this, {id: false, autoSave: false});
    Object.assign(this, _.omit(permission, ['accesses']));
    this.rootStore = rootStore;

    onBecomeObserved(this, 'connectorData', async () => {
      try {
        const res = await axios.get<ConnectorsDataT[]>(`/api/permissions/${this.id}/connectors_data`);
        runInAction(() => {
          this.connectorData = res.data;
        });
      } catch (err) {
        runInAction(() => {
          this.connectorData = [];
        });
      }
    });

    onBecomeObserved(this, 'accessesConnectorData', async () => {
      try {
        const res = await axios.get<ConnectorsDataT[]>(
          `/api/permissions/${this.id}/connectors_data?includeAccesses=true`
        );
        runInAction(() => {
          this.accessesConnectorData = res.data.filter((e) => e.refType === 'access');
        });
      } catch (err) {
        runInAction(() => {
          this.accessesConnectorData = [];
        });
      }
    });

    onBecomeObserved(this, 'serviceUsage', async () => {
      if (!this.rootResource.retrieveUsageDataEnabled) {
        runInAction(() => (this.serviceUsage = null));
        return;
      }
      const res = await axios.get<ConnectorsDataT[]>(`/api/permissions/${this.id}/connector_service_usages`);
      runInAction(() => (this.serviceUsage = res.data));
    });

    onBecomeObserved(this, 'permAccessIds', async () => {
      if (this.accessesLoadedAtLeastOnce) {
        return;
      }

      runInAction(() => {
        this.loadingAccesses = true;
      });

      const res = await axios.get<AccessResponseT>(`/api/resources/${this.rootResource.id}/accesses?pid=${this.id}`);

      runInAction(() => {
        res.data.forEach((attributes) => {
          this.rootResource.upsertAccess(attributes as AccessItemResponseT);
        });
        this.permAccessIds = res.data.map((a) => a.id);

        this.loadingAccesses = false;
        this.accessesLoadedAtLeastOnce = true;
      });
    });
  }

  async update(updates: Partial<Permission>): Promise<void> {
    let updated: any;
    try {
      updated = await axios.post(`/api/permissions/${this.id}/update`, {
        permission: {
          ...updates,
          oid: this.oid,
          refId: this.refId,
          refType: this.refType,
          id: this.id,
          label: updates.label ?? this.label,
          description: updates.description ?? this.description,
        },
      });

      runInAction(() => {
        if (updated.data && updated.status) {
          Object.assign(this, updated.data);
        }
      });
      this.rootStore.toast.add(`Successfully updated permission`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err) {
      this.rootStore.toast.add(`There was an error updating the permission`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  get parentResource(): Resource {
    return this.rootStore.newResourceStore.resourceMap[this.refId];
  }

  get rootResource(): Resource {
    return this.parentResource.rootResource;
  }

  get ancestorIds(): string[] {
    const ancestorIds: string[] = _.map(this.parentResource.allAncestors, 'id');
    ancestorIds.push(this.id, this.parentResource.id);
    return ancestorIds;
  }

  get accesses(): AccessRecord[] {
    return (this.rootResource.allNestedAccessRecords ?? []).filter((a) => a.pid === this.id);
  }

  get userAccesses(): AccessRecord[] {
    return (this.rootResource.currentUserAccessRecords ?? []).filter((a) => a.pid === this.id && !a.forAccount?.refId);
  }

  //Get access that belong to user
  get userTypeAccess(): AccessRecord[] {
    return (this.rootResource.allNestedAccessRecords ?? []).filter((a) => a.pid === this.id && !a.forAccount?.refId);
  }

  //Get access that does not belong to user
  get teamAccesses(): AccessRecord[] {
    return (this.rootResource.allNestedAccessRecords ?? []).filter((a) => a.pid === this.id && a.forAccount?.refId);
  }

  //New method to gather accesses for a permission
  get permAccess(): AccessRecord[] {
    return this.permAccessIds?.map((id: string) => this.rootStore.accessStore.accessMap[id]) ?? [];
  }

  get accessAggregated(): any[] {
    if (!this.rootResource.accessAggregatesByPid || !this.rootStore.accessStore) {
      return [];
    }

    return _.filter(this.rootResource.accessAggregatesByPid[this.id] ?? [], (a) => {
      return !a.ref;
    });
  }

  get teamsAccessAggregated(): any[] {
    if (!this.rootResource.accessAggregatesByPid || !this.rootStore.accessStore) {
      return [];
    }

    return _.filter(this.rootResource.accessAggregatesByPid[this.id] ?? [], (a) => {
      return a.ref;
    });
  }

  get memberCount(): number {
    return this.accesses.length;
  }

  //NR 30-11-23: We need to deprecate this, is triggering multiple unneeded calls.
  get usageData(): {usage: number; lastEventDate: string} | undefined {
    const permissionUsage = this.rootResource.permissionUsages?.[this.id];

    // Okta percentage of usage for each permission is calculated differently
    if (this.rootResource.type !== ConnectionServiceE.OKTA) {
      return permissionUsage ?? {usage: 0, lastEventDate: ''};
    }

    const accessCnt = _.size(this.accesses);
    let membersCnt = _.size(_.get(_.first(this.serviceUsage), 'account'));
    if (accessCnt < membersCnt) {
      membersCnt = accessCnt;
    }
    const percentage = accessCnt === 0 ? 0 : _.round((membersCnt / accessCnt) * 100);
    return {usage: percentage, lastEventDate: permissionUsage?.lastEventDate ?? ''};
  }

  get lastEvent(): string | undefined {
    const permissionUsage = this.rootResource.permissionUsages?.[this.id];

    return permissionUsage?.lastEventDate ?? '';
  }

  /** return true if current user is an owner of this permission's parent resource */
  get userIsOwner(): boolean {
    return this.parentResource.userIsOwner;
  }

  get sensitivity(): MinSensitivitySettingT | undefined {
    return this.sensitivityId
      ? _.find(this.rootStore.org?.sensitivitySettings, {id: this.sensitivityId})
      : this.parentResource.sensitivity;
  }

  get sensitivityIcon() {
    return <Icon type={_.find(SensitivityLevelDefinitions, {level: this.sensitivity?.level})?.icon ?? 'error'} />;
  }
  get hasMetadata() {
    if (_.isEmpty(this.connectorData)) {
      return false;
    }

    if (
      _.some(this.connectorData, (e) => e.dataType !== ConnectorsDataDataTypeE.ENTITY_DATA) ||
      _.some(this.connectorData, (e) => !_.isEmpty(e.data?.Tags)) ||
      _.some(this.connectorData, (e) => !_.isEmpty(e.data?.AttachedManagedPolicies)) ||
      _.some(this.connectorData, (e) => !_.isEmpty(e.data?.AssumeRolePolicyDocument))
    ) {
      return true;
    }
    return false;
  }

  get calcApprovalDuration(): {durationUnit: DurationUnitT; durationValue: number} {
    // overriden duration
    if (this.durationValue && this.durationUnit) {
      return {durationUnit: this.durationUnit, durationValue: this.durationValue};
    }

    // overriden sensitivity
    if (this.sensitivityId) {
      const {maxApprovalDurationUnit, maxApprovalDurationValue} = this.sensitivity!;
      return {durationValue: maxApprovalDurationValue, durationUnit: maxApprovalDurationUnit};
    }

    // inherited from resource
    return this.inheritedApprovalDuration;
  }

  get calcAccessDuration(): {durationUnit: DurationUnitT; durationValue: number} {
    // overriden duration
    if (this.accessDurationValue && this.accessDurationUnit) {
      return {durationUnit: this.accessDurationUnit, durationValue: this.accessDurationValue};
    }

    // overriden sensitivity
    if (this.sensitivityId) {
      const {maxAccessDurationUnit, maxAccessDurationValue} = this.sensitivity!;
      return {durationValue: maxAccessDurationValue, durationUnit: maxAccessDurationUnit};
    }

    // inherited from parent
    return this.inheritedAccessDuration;
  }

  get inheritedApprovalDuration(): {durationUnit: DurationUnitT; durationValue: number} {
    return this.parentResource.calcApprovalDuration;
  }

  get inheritedAccessDuration(): {durationUnit: DurationUnitT; durationValue: number} {
    return this.parentResource.calcAccessDuration;
  }

  get calculatedHidden(): {obj: any; value: boolean | undefined} {
    if (!_.isNil(this.hidden)) {
      return {obj: this, value: this.hidden};
    }
    if (this.parentResource?.calculatedHidden) {
      return {obj: this.parentResource, value: this.parentResource?.calculatedHidden.value};
    }
    return {obj: this, value: false};
  }

  get autoProvisionValue(): {obj: any; value: boolean | undefined} {
    return !_.isNil(this.autoProvision)
      ? {obj: this, value: this.autoProvision}
      : this.parentResource?.autoProvisionValue
      ? {obj: this.parentResource, value: this.parentResource?.autoProvisionValue.value}
      : {obj: this, value: false};
  }

  get calculatedProvisionMode(): {obj: any; value: ProvisionOptions | undefined} {
    return !_.isNil(this.provisionMode)
      ? {obj: this, value: this.provisionMode}
      : this.parentResource?.calculatedProvisionMode
      ? {obj: this.parentResource, value: this.parentResource?.calculatedProvisionMode.value}
      : {obj: this, value: ProvisionOptions.off};
  }

  /** this method should return the same value as this.sensitivity, but it also returns the obj from which it is inherited */
  get calculatedSensitivity(): {obj: any; value: string | undefined} {
    return !_.isNil(this.sensitivityId)
      ? {obj: this, value: this.sensitivityId}
      : this.parentResource?.calculatedSensitivity
      ? {obj: this.parentResource, value: this.parentResource?.calculatedSensitivity.value}
      : {obj: this.rootResource, value: this.sensitivity?.id};
  }

  get calculatedDeprovisionMode(): {obj: any; value: ProvisionOptions | undefined} {
    return !_.isNil(this.deprovisionMode)
      ? {obj: this, value: this.deprovisionMode}
      : this.parentResource?.calculatedDeprovisionMode
      ? {obj: this.parentResource, value: this.parentResource?.calculatedDeprovisionMode.value}
      : {obj: this, value: ProvisionOptions.off};
  }

  get calculatedInitiateExpiredDeprovision(): {obj: any; value: boolean | undefined} {
    return !_.isNil(this.initiateExpiredDeprovision)
      ? {obj: this, value: this.initiateExpiredDeprovision}
      : this.parentResource?.calculatedInitiateExpiredDeprovision
      ? {obj: this.parentResource, value: this.parentResource?.calculatedInitiateExpiredDeprovision.value}
      : {obj: this, value: false};
  }

  get riskScore(): number | undefined {
    return this.rootResource.permissionRisks?.[this.id]?.riskScore;
  }

  get riskReport(): any | undefined {
    return this.parentResource.riskReport?.[this.id];
  }

  get foundRisks(): string[] | undefined {
    return this.rootResource.permissionRisks?.[this.id]?.foundRisks;
  }

  /** request access for users in userIds, or for current user if userIds is empty or null */
  async requestAccess(params: {
    userIds?: string[];
    justification?: string;
    accessDuration?: DurationT;
  }): Promise<{errors: string[]; successes: string[]}> {
    const userIds = _.isEmpty(params.userIds) ? [this.rootStore.currentUser!.id] : params.userIds;
    const errors: string[] = [];

    const {accessDuration, justification = ''} = params;
    const {durationUnit = this.durationUnit, durationValue = this.durationValue} = accessDuration || {};

    const responses = _.compact(
      await Promise.all(
        userIds!.map((userId) =>
          axios
            .post<{accessRecord: AccessRecordT}>(`/api/permissions/${this.id}/requestaccess`, {
              request: {userId, justification, accessDurationUnit: durationUnit, accessDurationValue: durationValue},
            })
            .then(({data}) => ({...data.accessRecord, uid: data.accessRecord.uid ?? userId}))
            .catch((err) => {
              const {email} = this.rootStore.usersStore.usersMap[userId] || {};

              errors.push(`Error requesting access for ${email ?? 'user'} to ${this.label}: ${getErrorMessage(err)}`);
              return undefined;
            })
        )
      )
    );

    runInAction(() => {
      responses.forEach((accessRecord) => {
        this.rootResource.upsertAccess(accessRecord as AccessItemResponseT);
      });
    });

    const successes = responses.map(
      (r) => r.username ?? r.forUser?.email ?? this.rootStore.usersStore.usersMap[r.uid]?.email ?? ''
    );

    return {errors, successes};
  }

  /** @returns the newest access record associated with a user */
  access(uid: string): AccessRecord | undefined {
    return this.accesses.filter((access) => access.uid === uid).reduce((a, b) => (a.created > b.created ? a : b));
  }

  //TODO: remove unused code
  get accessStatuses(): any | undefined {
    return _.size(this.userTypeAccess) > 0
      ? _.map(
          _.countBy(this.userTypeAccess, (access) => access.statusValue),
          (count, status) => {
            return {key: status, count, label: this.rootStore.accessStore.statusByValue(parseInt(status))};
          }
        )
      : undefined;
  }

  get accessStatusesAggregated(): statusWKey[] | undefined {
    const groupedByValue = _.groupBy(this.accessAggregated, 'value');
    if (_.isEmpty(groupedByValue)) {
      return undefined;
    }

    const accessStatuses: statusWKey[] = [];
    _.forEach(groupedByValue, (group: any, key: string) => {
      accessStatuses.push({
        key: parseInt(key),
        count: _.size(group),
        label: this.rootStore.accessStore.statusByValue(parseInt(key)),
      });
    });

    return accessStatuses;
  }

  get teamsAccessStatusesAggregated(): statusWKey[] | undefined {
    const groupedByValue = _.groupBy(this.teamsAccessAggregated, 'value');
    if (_.isEmpty(groupedByValue)) {
      return undefined;
    }

    const accessStatuses: statusWKey[] = [];
    _.forEach(groupedByValue, (group: any, key: string) => {
      accessStatuses.push({
        key: parseInt(key),
        count: _.size(group),
        label: this.rootStore.accessStore.statusByValue(parseInt(key)),
      });
    });

    return accessStatuses;
  }

  /** deprovision permission for user */
  async deprovision(uid: string): Promise<void> {
    return this.access(uid)?.deprovision();
  }

  /** revoke permission for user */
  async revoke(uid: string): Promise<void> {
    return this.access(uid)?.revoke();
  }

  /** this computed makes it possible to call isLocked on a (Resource|Permission) type */
  get isLocked(): boolean {
    return _.isNil(this.locked) ? false : this.locked;
  }

  /** @returns true if current user can request this permission for themselves or another */
  get isRequestable(): boolean {
    if (this.tombstone || this.isLocked) {
      return false;
    }

    if (!this.rootResource.userIsOwner && this.calculatedHidden.value) {
      return false;
    }

    return !this.provisionModeOff;
  }

  /** @returns true if the permission is grantable (Capable of being granted) */
  get isGrantable() {
    // grant access
    if (this.tombstone || this.isLocked || !this.rootResource.userIsOwner || this.connectorModeDisabled) {
      return false;
    }

    return !this.provisionModeOff && !this.connectorModeDisabled;
  }

  //Gets active access for linked resources like/teams/repositories where the permission acts like a role.
  get accessActiveInPermission(): AccessRecord[] {
    return (
      this.accesses?.filter((access) => {
        return access.statusValue > 1;
      }) ?? []
    );
  }

  get provisionModeOff() {
    const isConnectedSystem = this.rootResource.isConnectedSystem;
    return isConnectedSystem && this.calculatedProvisionMode.value === ProvisionOptions.off;
  }

  get deprovisionModeOff() {
    const isConnectedSystem = this.rootResource.isConnectedSystem;
    return isConnectedSystem && this.calculatedDeprovisionMode.value === ProvisionOptions.off;
  }

  get connectorModeDisabled() {
    const isConnectedSystem = this.rootResource.isConnectedSystem;
    return isConnectedSystem && this.rootResource.connector?.disabled;
  }
}
