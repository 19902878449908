import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {ConnectionServiceE, ResourceTabMode} from 'src/types';
import ResourceForm from '../Edit/ResourceForm';
import {ResourceOptionT} from './ConnectResourceSelector';

export const FileBasedSystemMetadata: ResourceOptionT = {
  label: 'Universal (file-based)',
  description: 'Use a CSV file to update Trustle about changes to the external system.',
  link: '/docs/connectors/universal/csv_guide',
  redirect: `/resources/create/universal`,
  upperText: 'Create a',
  bottomText: 'File-based Integration',
  key: ConnectionServiceE.GENERIC,
};

function GenericConnector() {
  const history = useHistory();

  function onComplete(response: any) {
    const {resource} = response.data;
    history.push(`/resource/manage/${resource.id}${`/${ResourceTabMode.resources}`}`);
  }

  return (
    <div className="connect-resource-selector">
      <h2>{FileBasedSystemMetadata.label} System</h2>
      <p>
        {FileBasedSystemMetadata.description}
        <p>
          {'See '}
          <Link to={FileBasedSystemMetadata.link!} target="_blank">
            this guide
          </Link>
          {' for information on formatting.'}
        </p>
      </p>
      <div className="resource-section">
        <ResourceForm
          descriptionInTitle={true}
          isCreating={true}
          connectorType={ConnectionServiceE.GENERIC}
          cancel={() => {}}
          refresh={onComplete}
        />
      </div>
    </div>
  );
}

export default GenericConnector;
