import _ from 'lodash';
import React, {useState} from 'react';
import axios from 'axios';
import {SubmitButton} from 'src/components/design';
import {ConnectionInfoT, ConnectionT, DurationUnitT} from '../../types';
import {Formik, FormikForm} from 'src/components/design/formik';
import {Field} from 'formik';
import {Button, DurationInput, PasswordInput, Alert} from '@trustle/component-library';
import {useConnectionCredentials} from '../../lib/hooks';
import {useHistory} from 'react-router-dom';
import {useSystemSetup} from 'src/lib/hooks/useSystemSetup';
type AWSIDCPropsT = {
  onComplete: any;
  connectionInfo: ConnectionInfoT;
  editMode?: boolean;
};

function AWSIDCSetup(props: AWSIDCPropsT) {
  const {connectionInfo, editMode} = props;
  const history = useHistory();
  const [connection] = useState<ConnectionT>(connectionInfo.connection);
  const [APIsConnected, setAPIsConnected] = useState<boolean>(false);
  const [testInProgress, setTestInProgress] = useState<boolean>(false);
  const [info, setInfo] = useState<string>('');
  const {systemSetup, updateSetup} = useSystemSetup(connectionInfo.connection.id ?? '');
  const [infoType, setInfoType] = useState<string>('text-warning');
  const {credentials, testConnection: testConnectionAPI} = useConnectionCredentials();

  async function testConnection(credentials: {accessKeyId: string; secretAccessKey: string}) {
    setTestInProgress(true);
    setInfoType('text-warning');
    setInfo('Trying to authenticate ...');
    async function setError(response: any) {
      setTestInProgress(false);
      setAPIsConnected(false);
      setInfoType('text-danger');
      if (response.data?.error?.message) {
        setInfo(response.data?.error?.message);
      }
      return false;
    }

    try {
      const response = await testConnectionAPI(connection.id, credentials);
      if (response.data?.ok) {
        setTestInProgress(false);
        setAPIsConnected(true);
        setInfoType('text-success');
        setInfo('Successfully Connected');
        return response;
      } else {
        await setError(response);
      }
    } catch (err: any) {
      await setError(err);
    }
  }

  async function submitCredentials(
    values: {
      accessKeyId: string;
      secretAccessKey: string;
      accessKeyRotation: {durationValue: number; durationUnit: DurationUnitT};
    },
    setSubmitting: (submitting: boolean) => void
  ) {
    const tokens = _.pick(values, ['accessKeyId', 'secretAccessKey']);
    try {
      updateSetup({connectionId: connection.id, keys: values});
      await credentials(connection.id, {...tokens, accessKeyRotation: values.accessKeyRotation});
      const {data} = await axios.post(`/api/connect/${connection.id}/setup/complete`, {
        pollDurationUnit: 'hour',
        pollDurationValue: 24,
      });
      history.push(`/resource/manage/${data.systemId}`);
    } catch (err: any) {
      setInfoType('text-danger');
      setInfo(err.response.data?.error?.message || err.response.statusText);
    }

    setSubmitting(false);
  }

  return (
    <div className="py-4">
      <h3>API Key configuration</h3>
      <div>
        Trustle uses Access Key ID and Secret Acces Key to import and provision access to your AWS IDC account. Use
        these values to connect.
      </div>
      <small>
        <a href="https://learn.trustle.com/tutorial/installing-aws-idc" rel="noopener noreferrer" target="_blank">
          Guide: Setting up an AWS IDC Connector
        </a>
      </small>
      <div className="mt-4">
        <Formik
          initialValues={{
            accessKeyId: systemSetup?.accessKeyId ?? '',
            secretAccessKey: systemSetup?.secretAccessKey ?? '',
            accessKeyRotation: {
              durationValue: _.get(connectionInfo, 'connection.accessKeyRotationValue', 3),
              durationUnit: _.get(connectionInfo, 'connection.accessKeyRotationUnit', 'month'),
            },
          }}
          onSubmit={(values: any, {setSubmitting}: any) => {
            void submitCredentials(values, setSubmitting);
          }}
        >
          {({isSubmitting, status, isValid, dirty, values}) => (
            <FormikForm>
              <div>
                <div className="tr-grid tr-grid-cols-2 tr-gap-2">
                  <div>
                    <Field
                      component={PasswordInput}
                      label="Access Key ID"
                      placeholder="xxxxxxxxxxxxxxxxxxxx"
                      name="accessKeyId"
                      showreveal={true}
                      required
                    />
                  </div>
                  <div>
                    <Field
                      component={PasswordInput}
                      label="Secret Access Key"
                      placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                      name="secretAccessKey"
                      showreveal={true}
                      required
                    />
                  </div>
                </div>
                <div className="tr-grid tr-grid-cols-1 tr-mt-2 tr-pt-4 border-top tr-pl-0">
                  <Field
                    component={DurationInput}
                    name="accessKeyRotation"
                    label="Trustle Connector key rotation"
                    required
                    includeNoneOption={false}
                    description="Prompt me to rotate the Trustle access key in the period defined below"
                  />
                </div>

                <div className="text-left mt-5 tr-grid tr-grid-cols-1">
                  <div>
                    {false}
                    <div className={`${infoType}`}>{info}</div>
                    {status && status.message && <Alert colorVariant={'danger'}>{status.message}</Alert>}
                  </div>
                </div>
                <div className="text-right mt-5 mb-3">
                  <Button
                    name="edit-connection-save"
                    type="button"
                    disabled={!isValid || testInProgress || isSubmitting || !dirty}
                    onClick={async () => await testConnection(_.pick(values, ['accessKeyId', 'secretAccessKey']))}
                  >
                    {'Test Connection'}
                  </Button>
                  <SubmitButton
                    inSubmit={isSubmitting}
                    disabled={!APIsConnected || !isValid || isSubmitting || !dirty}
                    label={!editMode ? 'Continue' : 'Save Credentials'}
                  />
                </div>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
      <hr />
    </div>
  );
}

export default AWSIDCSetup;
