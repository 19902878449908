import React, {useState} from 'react';
import _ from 'lodash';
import {useHistory} from 'react-router-dom';
import './ConnectResourceSelector.scss';
import {ConnectionServiceE, FacetIdEnum} from 'src/types';
import {useRootStore} from 'src/lib/hooks';
import {FileBasedSystemMetadata} from './GenericConnector';
import {Button, Confirmation, Icon, TileButton, Tooltip} from '@trustle/component-library';
import {humanize, useFeatureFlags} from 'src/lib';
import buildIntegrationMetaSteps from 'src/connectors/manifest/ManifestMetadata';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';

export type ResourceOptionT = {
  label: string;
  description: string;
  link?: string;
  redirect: string;
  upperText?: string;
  bottomText?: string;
  key: ConnectionServiceE;
};

export default function ConnectResourceSelector() {
  const history = useHistory();
  const featureFlagViewer = useFeatureFlags();
  const {newResourceStore} = useRootStore();
  const metadatas = buildIntegrationMetaSteps();
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const {org} = useRootStore();

  if (_.isNil(org)) {
    return null;
  }

  const manifestFlowEnabled = featureFlagViewer.isEnabled('manifest_flow');
  const m365ConnectorEnabled = featureFlagViewer.isEnabled('enable_m365_integration');
  const awsIdcConnectorEnabled = featureFlagViewer.isEnabled('enable_aws_identity_center_connector');
  const signInText = 'Sign in to';
  const connectors: _.Dictionary<ResourceOptionT> = {
    okta: {
      label: 'Okta',
      description: 'Automated Okta resource management',
      redirect: `/connect/${ConnectionServiceE.OKTA}`,
      upperText: signInText,
      bottomText: 'Okta',
      key: ConnectionServiceE.OKTA,
    },
    github: {
      label: 'GitHub',
      description: 'Automated GitHub resource management',
      redirect: '/connect/github',
      upperText: signInText,
      bottomText: 'GitHub',
      key: ConnectionServiceE.GITHUB,
    },
    aws: {
      label: 'AWS',
      description: 'Automated AWS resource management',
      redirect: '/connect/aws',
      upperText: signInText,
      bottomText: 'AWS',
      key: ConnectionServiceE.AWS,
    },
    gapps: {
      label: 'Google',
      description: 'Automated Google Workspace resource management',
      redirect: '/connect/gapps',
      upperText: signInText,
      bottomText: 'Google',
      key: ConnectionServiceE.GAPPS,
    },
    azure_ad: {
      label: 'Azure',
      description: 'Automated Azure AD resource management',
      redirect: `/connect/${ConnectionServiceE.AZURE_AD}`,
      upperText: signInText,
      bottomText: 'Azure',
      key: ConnectionServiceE.AZURE_AD,
    },
    pagerduty: {
      label: 'PagerDuty',
      description: 'Automated PagerDuty management',
      redirect: `/connect/${ConnectionServiceE.PAGERDUTY}`,
      upperText: signInText,
      bottomText: 'PagerDuty',
      key: ConnectionServiceE.PAGERDUTY,
    },
    strongdm: {
      label: 'StrongDM',
      description: 'Automated StrongDM management',
      redirect: `/connect/${ConnectionServiceE.STRONGDM}`,
      upperText: signInText,
      bottomText: 'StrongDM',
      key: ConnectionServiceE.STRONGDM,
    },
    tableau: {
      label: 'Tableau',
      description: 'Automated Tableau management',
      redirect: `/connect/${ConnectionServiceE.TABLEAU}`,
      upperText: signInText,
      bottomText: 'Tableau',
      key: ConnectionServiceE.TABLEAU,
    },
  };
  if (m365ConnectorEnabled) {
    connectors['m365'] = {
      label: 'Microsoft 365',
      description: 'Automated Microsoft 365 resource management',
      redirect: `/connect/${ConnectionServiceE.M365}`,
      upperText: signInText,
      bottomText: 'M365',
      key: ConnectionServiceE.M365,
    };
  }

  if (manifestFlowEnabled) {
    for (const meta of metadatas) {
      if (meta.key === ConnectionServiceE.AWS_IDENTITY_CENTER && !awsIdcConnectorEnabled) {
        continue;
      }
      connectors[meta.key] = {
        label: meta.key,
        key: meta.key,
        description: meta.description,
        redirect: `/connect/${meta.key}`,
        upperText: signInText,
        bottomText: humanize(meta.key),
      };
    }
  }

  const nonConnectedOptions = {file: FileBasedSystemMetadata};

  const isFreeTrial = org.facetId === FacetIdEnum.FREE_AWS;

  async function startFlow(option: ResourceOptionT) {
    history.push(option.redirect);
  }

  const renderSystemButton = (option: ResourceOptionT) => {
    return (
      <Tooltip
        position="bottom"
        hideBackground={true}
        content={
          <div className="tr-text-sm tr-text-center tr-rounded-md tr-shadow-lg ">
            {option.upperText} {option.bottomText}
          </div>
        }
      >
        <TileButton
          title={`${option.upperText} ${option.bottomText}`}
          data-testid={_.lowerCase(option.key)}
          className="tr-mx-1"
          size="lg"
          onClick={() => {
            if (
              (isFreeTrial && _.lowerCase(option.label) !== 'aws') ||
              (isFreeTrial && _.size(newResourceStore.systems) > 0)
            ) {
              setShowUpgradeModal(true);
              return;
            }

            void startFlow(option);
          }}
          name={option.key as SystemIconsTypesEnum}
        />
      </Tooltip>
    );
  };

  const renderResourceOptions = (optionsMap: _.Dictionary<ResourceOptionT>) => {
    return _.map(optionsMap, (option: ResourceOptionT) => {
      return <div>{renderSystemButton(option)}</div>;
    });
  };

  return (
    <div className="connect-resource-selector">
      <div className="resource-section">
        <div className="tr-flex tr-justify-center tr-items-center tr-flex-col align-content-start tr-mb-4">
          <h1>Which system would you like to add today?</h1>
          <p className="tr-text-base tr-opacity-60">Select a system type and Sign-in to that system to get started</p>
        </div>

        <div className="tr-flex tr-flex-col">
          <div className="flex-wrap tr-flex tr-justify-center tr-items-center">{renderResourceOptions(connectors)}</div>
        </div>

        <div className="tr-flex-wrap tr-mt-5 tr-flex tr-justify-center tr-items-center">
          {!isFreeTrial && (
            <div className="tr-text-center">
              <div className="tr-flex tr-mb-4">
                <p className="tr-text-base tr-opacity-60 tr-pr-1">
                  {'Or just use our workflow engine and manage permissions via CSV or manually'}
                </p>
                <Tooltip
                  content={
                    <p>
                      <b>Workflow-only systems</b>
                      <p>
                        You can use Trustle's workflow to manage and document permissions any system.{' '}
                        <p>
                          For these systems, Trustle won't have API access, so you'll need to have someone complete the
                          work manually or you can use a CSV file to update Trustle.
                        </p>
                      </p>
                    </p>
                  }
                  size="md"
                >
                  <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                </Tooltip>
              </div>

              <div className="flex-wrap tr-flex tr-justify-center tr-items-center">
                {renderResourceOptions(nonConnectedOptions)}
              </div>
            </div>
          )}
          {isFreeTrial && (
            <div className="tr-flex tr-justify-center tr-items-center">
              <Button variant="ternary" onClick={() => history.push('/admin/billing/status')}>
                {'Upgrade here to add more systems'}
              </Button>
            </div>
          )}
        </div>
      </div>
      {showUpgradeModal && (
        <Confirmation
          onConfirm={() => {
            history.push('/admin/billing/plan');
          }}
          onClose={() => {
            setShowUpgradeModal(false);
          }}
          title="Upgrade to add another connector"
          confirmButtonLabel="Upgrade"
        >
          {'To add another system, please upgrade to Teams version. You will get a free 14-day trial'}
        </Confirmation>
      )}
    </div>
  );
}
