import React, {useEffect, useState} from 'react';
import {Link, Route, Switch, useParams} from 'react-router-dom';
import {formatUserName, useFeatureFlags} from 'src/lib';
import {Badge, Icon, Loading, SystemIcons, Tab, UserAvatar} from '@trustle/component-library';
import EditProfileForm from './EditProfileForm';
import TeamsList from '../../admin/teams/TeamsList';
import {useRootStore} from 'src/lib/hooks';
import {observer} from 'mobx-react-lite';
import UserPermissions from './UserPermissions';
import {ProfileScreenContext, SourceOfTruthE} from 'src/types';
import {Modal} from 'react-bootstrap';
import _ from 'lodash';
import './Profile.scss';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';
import OrgEvents from 'src/views/Events/OrgEvents';
import {DashboardCard} from '../../../dashboard/DashboardCard';
import {SummaryHeader} from '../../../dashboard/summary-header/SummaryHeader';
import {M365LicenseUsageCard} from '../../../dashboard/m365-license-usage/M365LicenseUsageCard';

const Profile = observer(() => {
  const {id, activeTab} = useParams() as {id: string; activeTab: string};
  const [editing, setEditing] = useState(false);
  const {usersStore, newResourceStore: resourceStore, org, currentUser, accountsStore} = useRootStore();

  const featureFlagViewer = useFeatureFlags();
  const showM365LicenseUsage = featureFlagViewer.isEnabled('m365_licenses_usage');

  useEffect(() => {
    void resourceStore.loadSystems({includeArchived: false});
    updateUser();
  }, [id]);

  function updateUser() {
    void usersStore.refresh();
  }

  const targetUser = usersStore.usersMap[id];
  if (usersStore.loading || _.isNil(org) || _.isNil(targetUser)) {
    return <Loading />;
  }

  const targetUserAccesses = targetUser.nonPlaceholderAccesses.filter((access) => access.forSystem !== undefined);
  const m365Resource = targetUserAccesses.find((access) => access.forSystem?.type === 'm365');
  const m365LinkedAccount = accountsStore
    .userLinkedAccounts(targetUser.id)
    .filter((account) => account.forResource?.type === 'm365')[0];

  const status = targetUser.statusVariant;
  const tabs = ['current', 'history', 'team'];

  const isTrustleSoT = _.defaultTo(targetUser.sourceOfTruth, SourceOfTruthE.TRUSTLE) === SourceOfTruthE.TRUSTLE;
  const icon = isTrustleSoT ? (
    <Icon type="Trustle" title="Trustle" />
  ) : (
    <SystemIcons name={org.idpSettings?.service as SystemIconsTypesEnum} size={'xs'} />
  );

  return (
    <Tab.Group selectedIndex={tabs.indexOf(activeTab ?? 'current') ?? 0} className=" tr-flex tr-flex-col">
      <div className="from-sensitivity-blue tr-pt-4 tr-text-white tr-bg-trustle-royal">
        <div className="container">
          <div className="tr-flex tr-justify-between tr-items-center tr-pb-2">
            <div className="tr-flex tr-items-center tr-my-4">
              <UserAvatar text={formatUserName(targetUser)} size="sm" isOwner={targetUser.isOrgOwner} />
              <div className="tr-flex tr-flex-col tr-ml-4">
                <div className="tr-font-bold tr-text-lg">{formatUserName(targetUser)}</div>
                <span className="tr-mr-2 tr-opacity-60 tr-text-xs">{targetUser.email}</span>
              </div>
              <div className="tr-w-[1px] tr-h-[46px] relative tr-bg-gray-300 tr-mx-4"></div>

              <Badge className="text-white tr-p-1 tr-mr-2" variant={status.variant}>
                <div className="tr-w-[9px] tr-h-[9px] tr-bg-white tr-rounded-full relative tr-mr-2"></div>
                <span className="tr-text-xs">{status.text}</span>
              </Badge>
              <div className="tr-ml-2 tr-flex justify-content-center tr-mr-2">
                <a href="#" title={`Authority: ${targetUser.authorityLabel}`}>
                  {icon}
                </a>
              </div>
              {currentUser?.canEdit(ProfileScreenContext.PROFILE_INFO, id) && (
                <Icon
                  type={_.toLower(targetUser.authorityLabel) === SourceOfTruthE.TRUSTLE ? 'edit' : 'details'}
                  data-testid="editProfile"
                  className="tr-cursor-pointer tr-ml-2"
                  role="button"
                  onClick={() => setEditing(true)}
                />
              )}
            </div>
            <div className="tr-flex tr-justify-between">
              <div className="tr-flex tr-flex-col tr-font-bold tr-text-sm tr-mr-8">
                Organization: {org.name}
                <div className="tr-mt-1">
                  <Icon type="orgOwnerBatch" size="md" className="tr-mr-1" />
                  {targetUser.manager ? formatUserName(targetUser.manager) : 'No Manager'}
                </div>
              </div>
              <Icon type="EmployeeCircle" forcedSize={36} className="tr-opacity-100" />
            </div>
          </div>
          <Tab.List variant="folder" className="tr-mb-0">
            {tabs.map((t) => (
              <Tab
                key={t}
                as={Link}
                to={`/users/${id}/${t}`}
                className="aria-selected:tr-bg-white aria-selected:hover:tr-text-blue hover:tr-text-white hover:tr-no-underline tr-opacity-100 md:tr-px-4"
              >
                {_.upperFirst(t)}
              </Tab>
            ))}
          </Tab.List>
        </div>
      </div>

      <Switch>
        <Route path={`/users/${id}/current`}>
          {showM365LicenseUsage ? (
            <>
              <div className="tr-mt-2 tr-flex tr-flex-col tr-gap-4 container">
                <div className="tr-max-w-fit">
                  <SummaryHeader accessRecords={targetUserAccesses || []} />
                </div>
                {m365Resource && m365LinkedAccount && (
                  <M365LicenseUsageCard
                    accountExternalId={m365LinkedAccount.uniqueRemoteId}
                    resourceId={m365Resource.forResource.id}
                  />
                )}
                <DashboardCard>
                  <h3 className="tr-mb-0 tr-p-4">{targetUser.firstname} Current Accesses</h3>
                  <UserPermissions
                    user={targetUser}
                    canEdit={targetUser.canEdit(ProfileScreenContext.USER_PERMISSIONS, id)}
                    hideFilters
                  />
                </DashboardCard>
              </div>
            </>
          ) : (
            <UserPermissions
              user={targetUser}
              canEdit={targetUser.canEdit(ProfileScreenContext.USER_PERMISSIONS, id)}
            />
          )}
        </Route>

        <Route path={`/users/${id}/history`}>
          <div className="tr-mt-2 container">
            <OrgEvents entityType={'users'} id={id} />
          </div>
        </Route>
        <Route path={`/users/${id}/team`}>
          <div className="container">
            <TeamsList forUser={id} />
          </div>
        </Route>
      </Switch>

      {editing && (
        <Modal show={editing} onHide={() => setEditing(false)} centered>
          <Modal.Header closeButton className="tr-flex">
            <Modal.Title className="text-xl font-bold my-1">Profile Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditProfileForm user={targetUser} onClose={() => setEditing(false)} />
          </Modal.Body>
        </Modal>
      )}
    </Tab.Group>
  );
});

export default Profile;
