import {Stripe} from '@stripe/stripe-js';
import {loadStripe} from '@stripe/stripe-js/pure';
import axios from 'axios';
import _ from 'lodash';
import {makeAutoObservable, onBecomeObserved, runInAction} from 'mobx';
import {LegalAgreementT} from 'src/context';
import {
  ConnectionServiceE,
  FacetIdEnum,
  getConnectionServiceName,
  IDPIntegration,
  JWTToken,
  JWTTokenStateE,
  PackageT,
  PageableServiceT,
  SensitivityLevelsE,
  SensitivitySettingT,
  SetupStepT,
} from 'src/types';
import {BadgeVariant} from 'src/views/Resource/Account/TrustleBadge';
import {BillingAddressT} from 'src/views/signup/CheckoutForm';
import RootStore from '..';
import {OnCallList} from './OnCallList';
import {logger} from 'src/lib';
import {User} from './User';
import {AccountResponseT} from '../AccountStore';
import {Account} from './Account';

type BillingInfoT = {
  invoices?: any[];
  paymentMethods?: any[];
  upcoming?: any;
};

export type UserFilter = {
  name: string;
  owner: string; // uid
  query: string;
  private: boolean;
};

export type APIKeyInsertParams = {description: string; duration: number; unit: string};

export class Org {
  id!: string;
  env?: 'development' | 'production' | 'staging' | '';
  name: string = '';
  hostname: string = '';

  isSetup: boolean = true;
  ownerUids: string[] = [];
  pkgId: string = '';
  facetId: FacetIdEnum = FacetIdEnum.FREE_AWS;
  customerId?: string;
  publishableKeys: {stripe: string; authPubKey: string} = {stripe: '', authPubKey: ''};
  featureFlags: string[] = [];
  stripe: Promise<Stripe | null>;
  agreements: LegalAgreementT[] = [];
  sensitivitySettings: SensitivitySettingT[] = [];
  idpSettings: IDPIntegration | null = null;
  filters?: UserFilter[] = [];

  // ↓ lazily loaded properties ↓

  defaultSensitivityId?: string = undefined;
  package?: PackageT | null = undefined;
  subscription?: any = undefined;
  products?: any = undefined;
  steps?: SetupStepT[] = undefined;
  onCallLists?: OnCallList[] = undefined;
  pageablePermissions?: PageableServiceT[] = undefined;
  billing?: BillingInfoT = undefined;
  billingAddresses?: BillingAddressT[] = undefined;
  customer?: any = undefined;
  methods?: any[] = undefined;
  billableUsersCount?: any = undefined;
  idpLastImport?: {id: string | null; candidates: {creation: any[]; updates: any[]; switches: any[]}} = undefined;

  constructor(private rootStore: RootStore, attributes: Partial<Org & {agreements: LegalAgreementT[]}>) {
    Object.assign(this, attributes);
    makeAutoObservable(this, {id: false});

    this.stripe = loadStripe(this.publishableKeys.stripe);
    onBecomeObserved(this, 'defaultSensitivityId', async () => {
      void axios.get<{defaultSensitivityId: string}>(`/api/orgs/default-sensitivity`).then((res) => {
        runInAction(() => (this.defaultSensitivityId = res.data.defaultSensitivityId));
      });
    });

    onBecomeObserved(this, 'package', async () => {
      const res = await axios.get<{package: PackageT | null}>(`/api/packages/package`);
      runInAction(() => (this.package = res.data.package));
    });

    onBecomeObserved(this, 'subscription', async () => {
      if (this.rootStore.currentUser?.isOrgOwner) {
        const res = await axios.get('/api/payments/subscription');
        runInAction(() => (this.subscription = res.data));
      } else {
        runInAction(() => (this.subscription = null));
      }
    });

    onBecomeObserved(this, 'billableUsersCount', async () => {
      if (this.rootStore.currentUser?.isOrgOwner) {
        runInAction(() => (this.billableUsersCount = _.size(this.rootStore.usersStore.activeAndPendingUsers)));
      } else {
        runInAction(() => (this.billableUsersCount = null));
      }
    });

    onBecomeObserved(this, 'products', async () => {
      if (this.rootStore.currentUser?.isOrgOwner) {
        const res = await axios.get('/api/payments/products');
        runInAction(() => (this.products = _.keyBy(res.data, 'id')));
      } else {
        runInAction(() => (this.products = null));
      }
    });

    onBecomeObserved(this, 'steps', async () => {
      if (this.rootStore.currentUser?.isOrgOwner) {
        const res = await axios.get<{steps: SetupStepT[]}>(`/api/packages/package`);
        runInAction(() => (this.steps = res.data.steps));
      } else {
        runInAction(() => (this.steps = []));
      }
    });

    onBecomeObserved(this, 'onCallLists', async () => {
      const res = await axios.get<Partial<OnCallList>[]>(`/api/oncall_lists?users`);
      runInAction(() => {
        this.onCallLists = res.data.map((onCallList) => new OnCallList(this.rootStore, {...onCallList}));
      });
    });

    onBecomeObserved(this, 'pageablePermissions', async () => {
      const res = await axios.get(`/api/oncall_lists/pageable_permissions`);
      runInAction(() => (this.pageablePermissions = res.data));
    });

    onBecomeObserved(this, 'billing', async () => {
      if (this.customerId) {
        const res = await axios.get('/api/payments/billing_status');
        runInAction(() => (this.billing = res.data));
      }
    });

    onBecomeObserved(this, 'billingAddresses', async () => {
      const res = await axios.get('/api/payments/billing_address');
      runInAction(() => (this.billingAddresses = res.data));
    });

    onBecomeObserved(this, 'customer', async () => {
      const res = await axios.get('/api/payments/customer');
      runInAction(() => (this.customer = res.data));
    });

    onBecomeObserved(this, 'methods', async () => {
      const result = await axios.get('/api/payments/methods');
      runInAction(() => (this.methods = result.data));
    });

    onBecomeObserved(this, 'idpLastImport', async () => {
      const {service} = this.idpSettings || {};

      if (service) {
        const {data} = await axios.get(`/api/orgs/idp/ready?service=${service}`);

        runInAction(() => (this.idpLastImport = data?.id ? data : undefined));
      } else {
        runInAction(() => (this.idpLastImport = undefined));
      }
    });
  }

  get accountsDictionary(): _.Dictionary<Account[]> {
    if (!this.rootStore.newResourceStore.systems) {
      return {};
    }

    return _.groupBy(
      _.flatMap(this.rootStore.newResourceStore.systems, (system) => {
        return _.filter(system.accounts, (account) => !account.tombstone);
      }),
      'uid'
    );
  }

  get defaultSensitivitySetting() {
    return _.find(this.sensitivitySettings, {
      id: this.defaultSensitivityId,
    });
  }

  get subscriptionPlan(): any {
    return this.subscription && this.products ? this.products[this.subscription.plan?.product] : undefined;
  }

  get subscriptionPrice(): any {
    return this.subscriptionPlan
      ? this.subscriptionPlan.prices.find((price: any) => {
          return price.id === this.subscription.plan?.id;
        })
      : undefined;
  }

  get subscriptionStatus(): string {
    if (!this.subscription) {
      return '';
    }

    if (this.subscription.cancel_at) {
      return BadgeVariant.WARNING;
    }

    switch (this.subscription.status) {
      case 'trialing':
      case 'active': {
        return BadgeVariant.SUCCESS;
      }
      case 'incomplete':
      case 'incomplete_expired':
      case 'past_due': {
        return BadgeVariant.WARNING;
      }
      case 'canceled':
      case 'unpaid': {
        return BadgeVariant.DANGER;
      }
      default: {
        return BadgeVariant.INFO;
      }
    }
  }

  get oncallListData() {
    return _.compact(
      (this.onCallLists ?? []).map((ol) => {
        return {id: ol.id, value: ol.id, label: ol.name};
      })
    );
  }

  get orgUsersAuthorityLabel() {
    return _.isNil(this.idpSettings) ? 'Trustle' : `${getConnectionServiceName(this.idpSettings?.service)} IDP`;
  }

  getProductByName(planName: string) {
    return _.find(this.products, {name: planName});
  }

  async createSubscription(params: {
    quantity: number;
    priceId?: string;
    paymentMethodId?: string;
    customerId?: string;
  }) {
    const res = await axios.post(`/api/payments/subscription`, {...params, orgName: this.name});
    this.subscription = res.data;
    return this.subscription;
  }

  async updateSubscription(params: {
    quantity: number;
    priceId?: string;
    paymentMethodId?: string;
    customerId?: string;
  }) {
    const res = await axios.patch(`/api/payments/subscription`, {...params, orgName: this.name});
    this.subscription = res.data;
    return this.subscription;
  }

  async cancelSubscription() {
    this.subscription = null;
    await axios.post('/api/payments/subscription/cancel');
  }

  async markSetupStepDone(id: string) {
    await axios.post(`/api/setup_step/${id}`);
    this.steps = (this.steps ?? []).map((step) => ({...step, finalized: id === step.id ? true : step.finalized}));
  }

  async createOnCall(values: {name: string; users?: string[]; permissionId?: string}) {
    const res = await axios.post(`/api/oncall_lists`, {name: values.name, permissionId: values.permissionId});
    if (!_.isEmpty(values.users)) {
      await axios.put(`/api/oncall_lists/${res.data.oncallList.id}/oncall_users`, values.users);
    }
    const lists = await axios.get<Partial<OnCallList>[]>(`/api/oncall_lists?users`);
    this.onCallLists = lists.data.map((onCallList) => new OnCallList(this.rootStore, {...onCallList}));
  }

  async deleteOnCallList(target: OnCallList) {
    await axios.delete(`/api/oncall_lists/${target.id}`);
    this.onCallLists = (this.onCallLists ?? []).filter((list) => list.id !== target.id);
  }

  async updateCustomer(values: {name: string; phone: string; email: string}) {
    const res = await axios.post('/api/payments/customer', values);
    this.customer = res.data;
  }

  async updateHostname(orgName: string) {
    await axios.post(`/api/orgs/admin/hostname/${orgName}`);
    this.hostname = orgName;
  }

  async removeBillingAddress(id: string) {
    await axios.post(`/api/payments/billing_address/${id}`);
    this.billingAddresses = (this.billingAddresses ?? []).filter((billing) => {
      return billing.id !== id;
    });
  }

  async removePaymentMethod(id: string) {
    await axios.post(`/api/payments/methods/${id}`);
    this.methods = (this.methods ?? []).filter((method) => {
      return method.id !== id;
    });
  }

  async setDefaultSensitivityId(id: string) {
    await axios.post(`/api/orgs/admin/configuration`, {defaultSensitivityId: id});
    runInAction(() => (this.defaultSensitivityId = id));
  }

  async updateSensitivitySettings(settingId: string, updatedValues: SensitivitySettingT) {
    const res = await axios
      .put(`/api/sensitivities/${settingId}`, updatedValues)
      .then(() => axios.get(`/api/sensitivities`));
    runInAction(() => (this.sensitivitySettings = res.data.sensitivitySettings));
  }

  async addFilter(filter: UserFilter) {
    filter.name = _.trim(filter.name);

    const duplicatedName = _.find(this.filters || [], (f) => {
      return f.name === filter.name && f.owner === filter.owner;
    });

    if (duplicatedName) {
      this.rootStore.toast.error('Filter name already exists');
      return;
    }

    const filters = [...(this.filters || []), filter];
    await this.performFilterOperation(filters, 'created');
  }

  async updateFilter(uid: string, name: string, params: {name?: string; query?: string; private?: boolean}) {
    if (_.isEmpty(params)) {
      return;
    }

    if (!_.isEmpty(params.name)) {
      params.name = _.trim(params.name);

      const duplicatedName = _.find(this.filters || [], (f) => {
        return f.name === params.name && f.owner === uid;
      });

      if (duplicatedName) {
        this.rootStore.toast.error('Filter name already exists');
        return;
      }
    }

    const filters = _.map(this.filters || [], (f) => {
      return f.name === _.trim(name) && f.owner === uid ? {...f, ...params} : f;
    });

    await this.performFilterOperation(filters, 'updated');
  }

  async deleteFilter(uid: string, name: string) {
    _.remove(this.filters || [], (f) => {
      return f.name === _.trim(name) && f.owner === uid;
    });

    await this.performFilterOperation(this.filters || [], 'deleted');
  }

  async performFilterOperation(filters: UserFilter[], op: string) {
    try {
      await axios.post(`/api/orgs/admin/configuration`, {filters});

      runInAction(() => (this.filters = filters));
      this.rootStore.toast.success(`Filter succesfully ${op}`);
    } catch (err: any) {
      logger.error(`Filter couldn't be ${op}`, _.get(err, 'response.data'));
      this.rootStore.toast.error(`Filter couldn't be ${op}`);
    }
  }

  async getApiKeys(): Promise<JWTToken[]> {
    try {
      const {data} = await axios.get<JWTToken[]>('/api/auth/api_key/all');
      return data;
    } catch (err) {
      this.rootStore.toast.error("API Keys couldn't be retrieved");
      logger.error('Error retrieving API Keys', _.get(err, 'response.data'));

      return [];
    }
  }

  async generateAPIKey(params: APIKeyInsertParams): Promise<(JWTToken & {apiKey: string}) | null> {
    const {description, duration = 3, unit = 'M'} = params;

    try {
      const {data} = await axios.get(`/api/auth/api_key?duration=${duration}&unit=${unit}&description=${description}`);

      return data;
    } catch (err: any) {
      const message = _.get(err, 'response.data.error.message');

      logger.error(`Error generating API Key`, err.response.data);
      this.rootStore.toast.error(message || 'Error generating API Key');

      return null;
    }
  }

  async patchApiKey(id: string, params: {description?: string; state?: JWTTokenStateE}): Promise<any> {
    try {
      const {data} = await axios.put('/api/auth/api_key', {...params, jwtid: id});

      this.rootStore.toast.success('API Key succesfully updated');
      return data;
    } catch (err) {
      const message = _.get(err, 'response.data.error.message');

      this.rootStore.toast.error(message ?? "API Key couldn't be updated");
      logger.error('Error updating API Key', _.get(err, 'response.data'));

      return null;
    }
  }

  //TODO: check this and remove if unnecessary in new login flow
  async markSetupComplete() {
    await axios.post('/api/setup/setup_complete');
    runInAction(() => (this.isSetup = true));
  }

  async triggerUsersSync() {
    try {
      const {data} = await axios.get('/api/orgs/idp/refresh');
      runInAction(() => (this.idpLastImport = data));

      this.rootStore.toast.success('Successfully synced against Authority');
    } catch (err) {
      logger.error('Failed to sync users against Authority', _.get(err, 'response.data'));
      this.rootStore.toast.error('Failed to sync users against Authority');
    }
  }

  getOrgRedirectUrl(path: string) {
    const host = (() => {
      const orgHostname = this.hostname || 'app';

      return this.env === 'production'
        ? `https://${orgHostname}.trustle.io`
        : this.env === 'staging'
        ? `https://${orgHostname}.trustle-stg.xyz`
        : `http://${orgHostname}.local.trustle.xyz:3000`;
    })();
    return `${host}${path}`;
  }

  async confirmUserSelection(selected: string[], autolink: boolean, rid?: string) {
    try {
      if (_.isEmpty(selected)) {
        return;
      }

      const {data} = await axios.post(`/api/orgs/idp/confirm`, {
        importId: this.idpLastImport?.id,
        selected,
        autolink,
        rid,
      });
      const {results, users, accounts} = data;

      const creationError: string[] = [];
      const updatesError: string[] = [];

      _.each(_.keys(results), (k) => {
        const {action, error} = results[k];

        if (error && _.includes(selected, k)) {
          if (action === 'creation') {
            creationError.push(k);
          }
          if (action === 'update') {
            updatesError.push(k);
          }
        }
      });

      if (!_.isEmpty(creationError)) {
        const emails = _.join(creationError, ', ');
        this.rootStore.toast.error(`Error creating user(s): ${emails}`);
      }
      if (!_.isEmpty(updatesError)) {
        const emails = _.join(updatesError, ', ');
        this.rootStore.toast.error(`Error updating user(s): ${emails}`);
      }

      if (_.isEmpty(creationError) && _.isEmpty(updatesError)) {
        this.rootStore.toast.success('Users succesfully synced');
      }

      _.reduce(
        users,
        (acc: any, user: User) => {
          this.rootStore.usersStore.updateUserFromServer(user);
          acc[user.id] = user;
          return acc;
        },
        {} as Record<string, User>
      );

      if (autolink) {
        _.reduce(
          accounts,
          (acc: any, account: AccountResponseT) => {
            this.rootStore.accountsStore.updateAccountFromServer(account);
            acc[account.id] = account;
            return acc;
          },
          {} as Record<string, User>
        );

        if (!_.isEmpty(accounts)) {
          logger.log(`Linked accounts: ${_.join(_.map(accounts, 'account'), ', ')}`);
          this.rootStore.toast.success(`${_.size(accounts)} account(s) linked`);
        }
      }
      await this.rootStore.usersStore.refresh();
      return results;
    } catch (err) {
      logger.error(err);
      this.rootStore.toast.error('Failed to confirm selection');
    }
  }

  /**
   * Sensitivity Settings sorted by name Levels
   */
  get sensitivitySettingsSorted() {
    return _.sortBy(this.sensitivitySettings, (setting) => {
      switch (setting.level) {
        case SensitivityLevelsE.NONE:
          return 1;
        case SensitivityLevelsE.LOW:
          return 2;
        case SensitivityLevelsE.MEDIUM:
          return 3;
        case SensitivityLevelsE.HIGH:
          return 4;
        case SensitivityLevelsE.CRITICAL:
          return 5;
        default:
          return 6;
      }
    });
  }
  /**
   * Return all filters that are not private or are private and owned by the current user
   */
  get myFilters() {
    return _.filter(this.filters, (f) => !f.private || f.owner === this.rootStore.currentUser?.id);
  }

  // At least one IDP integration is enabled in this org
  get idpFeatureEnabled() {
    return true;
  }

  isIDPFeatureEnabled(service: ConnectionServiceE | undefined): boolean {
    switch (service) {
      case ConnectionServiceE.OKTA:
        return true;

      case ConnectionServiceE.AZURE_AD:
        return true;

      case ConnectionServiceE.GAPPS:
        return true;

      default:
        return false;
    }
  }
}
